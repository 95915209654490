import { useEffect } from 'react';
import Floor from '../Floor';
import Wrapper from '../../../assets/wrappers/FloorsContainer';
import { useSelector, useDispatch } from 'react-redux';
import Loading from '../../Loading';
import { getAllFloors } from '../../../features/allFloors/allFloorsSlice';
import AllFloorsPageBtnContainer from './AllFloorsPageBtnContainer';
import Modal from '../../Modal';
import { useGlobalContext } from '../../context';
import { FloorInfoContainer } from '../../';
import { clearValues } from '../../../features/floor/floorSlice';



const FloorsContainer = () => {
  const {
    floors,
    areFloorsLoading,
    page,
    totalFloors,
    numOfPages,
    search,
  } = useSelector((store) => store.allFloors);
  const dispatch = useDispatch();
  const { openSidebar, openModal } = useGlobalContext();
  const openModalAux = () => {
    dispatch(clearValues());
    openModal();
  };


  useEffect(() => {
    dispatch(getAllFloors());
  }, [page, search]);

  if (areFloorsLoading) {
    return <Loading />;
  }

  if (floors.length === 0) {
    return (
    <Wrapper>
        <button onClick={openModalAux} className='btn'>
            Agregar piso
        </button>
        <Modal>
            <FloorInfoContainer />
        </Modal>
        <h5></h5>
        <h2>No hay pisos para mostrar...</h2>
    </Wrapper>
    );
  }

  return (
    <Wrapper>
      <h5>
              {/*  {totalFloors} piso{floors.length > 1 && 's'} encontrado{floors.length > 1 && 's'}*/}
      </h5>
      
      <div className='floors'>
      <button onClick={openModalAux} className='btn'>
          Agregar piso
        </button>
        <Modal>
          <FloorInfoContainer/>
        </Modal>
        {floors.map((floor) => {
          return <Floor key={floor.rec_Id} {...floor} />;
        })}
      </div>
      {numOfPages > 1 && <AllFloorsPageBtnContainer />}
    </Wrapper>
  );
};
export default FloorsContainer;
