const FormRowInfo = ({name, value, labelText, placeholder, visible}) => {
  if (visible == true)
  {
    return (
      <div className="form-row">
        <b><label htmlFor={name} className="form-label">
          {labelText || name}
        </label></b>
        <p>
          {value}
        </p>
      </div>
    );
  }
  else
  {
    return (
      <>
      </>
    );
  }
};

export default FormRowInfo;