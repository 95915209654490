import customFetch, { checkForUnauthorizedResponse } from "../../utils/axios";
import { logoutUser } from './userSlice';
import { clearAllEmployeesState } from '../allEmployees/allEmployeesSlice';
import { getAllUsers } from '../allUsers/allUsersSlice';
import { clearValues } from '../employee/employeeSlice';

export const registerUserThunk = async(url, user, thunkAPI) => {
    try 
    {
        const resp = await customFetch.post(url, user);
        return resp.data;
    }
    catch (error) 
    {
        return thunkAPI.rejectWithValue(error.responde.data.msg);
    }
}

export const loginUserThunk = async(url, user, thunkAPI) => {
    try
    {
        const resp = await customFetch.post(url, user);
        return resp.data;
    }
    catch(error)
    {
        return thunkAPI.rejectWithValue(error.response.data.msg);
    }   
}

export const updateUserThunk = async(url, user, thunkAPI) => {
    try
    {
        const resp = await customFetch.patch(url, user, {
            headers:{
                authorization:`Bearer ${thunkAPI.getState().user.user.token}`
            }
        });
        return resp.data;
    }
    catch (error)
    {
        if (error.response.status === 401)
        {
            thunkAPI.dispatch(logoutUser());
            return thunkAPI.rejectWithValue('Unauthorized! Logging out...');
        }
        return thunkAPI.rejectWithValue(error.response.data.msg);
    }
};

export const createUserThunk = async (user, thunkAPI) => {

    try {
        const resp = await customFetch.post('/User/Create', user);
        thunkAPI.dispatch(getAllUsers());
        return resp.data;
    } catch (error) {
        checkForUnauthorizedResponse(error, thunkAPI);
    }
};

export const editUserThunk = async ({ personRecId, user }, thunkAPI) => {
    try {
        const resp = await customFetch.patch(`/User/${personRecId}`, user);
        //thunkAPI.dispatch(clearValues());
        return resp.data;
    } catch (error) {
        return checkForUnauthorizedResponse(error, thunkAPI);
    }
};

export const deleteUserThunk = async (personRecId, thunkAPI) => {
    try {
        const resp = await customFetch.delete(`/User/${personRecId}`);
        thunkAPI.dispatch(getAllUsers());
        return resp.data.msg;
    } catch (error) {
        return checkForUnauthorizedResponse(error, thunkAPI);
    }
};

/**
 * 
 * @param {any} message
 * @param {any} thunkAPI
 */
export const clearStoreThunk = async (message, thunkAPI) => {
    try {
      // logout user
      thunkAPI.dispatch(logoutUser(message));
      // clear jobs value
      thunkAPI.dispatch(clearAllEmployeesState());
      // clear job input values
      thunkAPI.dispatch(clearValues());
      return Promise.resolve();
    } catch (error) {
      // console.log(error);
      return Promise.reject();
    }
  };