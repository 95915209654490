import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { getUserFromLocalStorage } from '../../utils/localStorage';
import {
    createEmployeeThunk,
    deleteEmployeeThunk,
    editEmployeeThunk,
    getEmployeeSlotsThunk,
    getEmployeeDepartmentsThunk,
    addDepartmentsToEmployeeThunk,
    deleteEmployeeDepartmentThunk,
    getEmployeeInfoThunk,
    assignEmployeeSlotThunk
} from './employeeThunk';

const initialDepartmentsFiltersState = {
    departmentSearch: '',
    /* searchStatus: 'all',
     searchType: 'all',
     sort: 'latest',
     sortOptions: ['latest', 'oldest', 'a-z', 'z-a'],*/
};

const initialSlotsFiltersState = {
    floorSearch: 0,
 /* searchStatus: 'all',
  searchType: 'all',
  sort: 'latest',
  sortOptions: ['latest', 'oldest', 'a-z', 'z-a'],*/
};

const initialState = {
    isLoading: false,
    person_Id: '',
    person_Rec_Id: '',
    firstName: '',
    lastName: '',
    badge_Id: '',
    email: '',
    createdDateTime: '',
    updatedDateTime: '',
    createdBy: '',
    updatedBy: '',
    isEditing: false,
    editEmployeeRecId: '',
    areSlotsLoading: true,
    areDepartmentsLoading: true,
    departments: [],
    totalDepartments: 0,
    numOfDepartmentsPages: 1,
    departmentsPage: 1,
    departmentsLimit: 10,
    allocations: {},
    slotAssigned: {},
    ...initialDepartmentsFiltersState,
    ...initialSlotsFiltersState,
};

export const createEmployee = createAsyncThunk('employee/createEmployee', createEmployeeThunk);
export const deleteEmployee = createAsyncThunk('employee/deleteEmployee', deleteEmployeeThunk);
export const editEmployee = createAsyncThunk('employee/editEmployee', editEmployeeThunk);
export const getEmployeeSlots = createAsyncThunk('employee/getEmployeeSlots', getEmployeeSlotsThunk);
export const getEmployeeDepartments = createAsyncThunk('employee/getEmployeeDepartments', getEmployeeDepartmentsThunk);
export const addDepartmentsToEmployee = createAsyncThunk('employee/addDepartments', addDepartmentsToEmployeeThunk);
export const deleteEmployeeDepartment = createAsyncThunk('employee/deleteDepartment', deleteEmployeeDepartmentThunk);
export const getEmployeeInfo = createAsyncThunk('employee/getEmployeeInfo', getEmployeeInfoThunk);
export const assignEmployeeSlot = createAsyncThunk('employee/assignEmployeeSlot', assignEmployeeSlotThunk);


const employeeSlice = createSlice({
  name: 'employee',
  initialState,
  reducers: {
    handleChange: (state, { payload: { name, value } }) => {
        state[name] = value;
    },
    clearValues: () => {
        return {
        ...initialState,
        //jobLocation: getUserFromLocalStorage()?.location || '',
        };
    },
    setEditEmployee: (state, { payload }) => {
        return { ...state, isEditing: true, ...payload };
    },
    showSlotsLoading: (state) => {
        state.areSlotsLoading = true;
    },
    hideSlotsLoading: (state) => {
        state.areSlotsLoading = false;
    },
   
    clearSlotsFilters: (state) => {
        return { ...state, ...initialSlotsFiltersState };
    },

    /*DEPARTMENTS*/
    showDepartmentsLoading: (state) => {
        state.areDepartmentsLoading = true;
    },
    hideDepartmentsLoading: (state) => {
        state.areDepartmentsLoading = false;
    },
      handleDepartmentsChange: (state, { payload: { name, value } }) => {
        state.departmentsPage = 1;
        state[name] = value;
    },
    clearDepartmentsFilters: (state) => {
        return { ...state, ...initialDepartmentsFiltersState };
    },
    changeDepartmentsPage: (state, { payload }) => {
        state.departmentsPage = payload;
    },
  },
  extraReducers: {
    [createEmployee.pending]: (state) => {
      state.isLoading = true;
    },
    [createEmployee.fulfilled]: (state) => {
      state.isLoading = false;
      toast.success('Colaborador creado');
    },
    [createEmployee.rejected]: (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload);
    },
    [deleteEmployee.fulfilled]: (state, { payload }) => {
      toast.success(payload);
    },
    [deleteEmployee.rejected]: (state, { payload }) => {
      toast.error(payload);
    },
    [editEmployee.pending]: (state) => {
      state.isLoading = true;
    },
    [editEmployee.fulfilled]: (state) => {
      state.isLoading = false;
      toast.success('Colaborador actualizado...');
    },
    [editEmployee.rejected]: (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload);
    },
    [getEmployeeSlots.pending]: (state) => {
      
        state.areSlotsLoading = true;
    },
    [getEmployeeSlots.fulfilled]: (state, { payload }) => {
        state.areSlotsLoading = false;
        state.allocations = payload.allocations;
    },
    [getEmployeeSlots.rejected]: (state, { payload }) => {
        state.areSlotsLoading = false;
        toast.error(payload);
      },
    [getEmployeeInfo.pending]: (state) => {

        state.isLoading = true;
    },
    [getEmployeeInfo.fulfilled]: (state, { payload }) => {
        state.isLoading = false;
        state.person_Id = payload.employee.person_Id;
        state.editEmployeeRecId = payload.employee.rec_Id;
        state.person_Rec_Id = payload.employee.person_Rec_Id;
        state.firstName = payload.employee.firstName;
        state.lastName = payload.employee.lastName;
        state.slotAssigned = payload.employee.slotAssigned;
    },
    [getEmployeeInfo.rejected]: (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
    },
      [assignEmployeeSlot.pending]: (state) => {
      },
      [assignEmployeeSlot.fulfilled]: (state, { payload }) => {
          toast.success('Espacio asignado.');
      },
      [assignEmployeeSlot.rejected]: (state, { payload }) => {
          toast.error(payload);
      },

    /*
     * 
     * Departments
     */
      [getEmployeeDepartments.pending]: (state) => {

          state.areSlotsLoading = true;
      },
      [getEmployeeDepartments.fulfilled]: (state, { payload }) => {
          state.areDepartmentsLoading = false;
          state.departments = payload.departments;
          state.numOfDepartmentsPages = payload.numOfPages;
          state.totalDepartments = payload.totalDepartments;
      },
      [getEmployeeDepartments.rejected]: (state, { payload }) => {
          state.areDepartmentsLoading = false;
          toast.error(payload);
      },
      [addDepartmentsToEmployee.pending]: (state) => {
          state.areDepartmentsLoading = true;
      },
      [addDepartmentsToEmployee.fulfilled]: (state) => {
          state.areDepartmentsLoading = false;
          toast.success('Departamentos agregados');
      },
      [addDepartmentsToEmployee.rejected]: (state, { payload }) => {
          state.areDepartmentsLoading = false;
          toast.error(payload);
      },
      [deleteEmployeeDepartment.pending]: (state) => {
          state.areDepartmentsLoading = true;
      },
      [deleteEmployeeDepartment.fulfilled]: (state) => {
          state.areDepartmentsLoading = false;
          toast.success('Departamento eliminado');
      },
      [deleteEmployeeDepartment.rejected]: (state, { payload }) => {
          state.areDepartmentsLoading = false;
          toast.error(payload);
      },
  },
});

export const { 
    handleChange, 
    clearValues, 
    setEditEmployee,
    showSlotsLoading,
    hideSlotsLoading,
    handleSlotsChange,
    clearSlotsFilters,
    changeSlotsPage,
    hideDepartmentsLoading,
    handleDepartmentsChange,
    clearDepartmentsFilters,
    changeDepartmentsPage,
} = employeeSlice.actions;

export default employeeSlice.reducer;
