import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import {
    getAllSlotsThunk,
    showStatsThunk,
    getSlotsAllocationsThunk,
    getSlotsAllocationsByDepartmentThunk,
    getSlotsByDepartmentThunk,
} from './allSlotsThunk';

const initialFiltersState = {
    searchFloor: 1,
    searchDepartment: 0,
 /* searchStatus: 'all',
  searchType: 'all',
  sort: 'latest',
  sortOptions: ['latest', 'oldest', 'a-z', 'z-a'],*/
};

const initialState = {
    isLoading: true,
    slots: [],
    totalSlots: 0,
    numOfPages: 1,
    page: 1,
    stats: {},
    allocations: {},
    ...initialFiltersState,
};

export const getAllSlots = createAsyncThunk('allSlots/getSlots', getAllSlotsThunk);
export const getSlotsByDepartment = createAsyncThunk('allSlots/getSlotsByDepartment', getSlotsByDepartmentThunk);
export const showStats = createAsyncThunk('allSlots/showStats', showStatsThunk);
export const getAllocations = createAsyncThunk('allSlots/allocations', getSlotsAllocationsThunk);
export const getAllocationsByDepartment = createAsyncThunk('allSlots/DepartmentAllocations', getSlotsAllocationsByDepartmentThunk);


const allSlotsSlice = createSlice({
  name: 'allSlots',
  initialState,
  reducers: {
    showLoading: (state) => {
      state.isLoading = true;
    },
    hideLoading: (state) => {
      state.isLoading = false;
    },
    handleChange: (state, { payload: { name, value } }) => {
      state.page = 1;
      state[name] = value;
    },
    clearFilters: (state) => {
      return { ...state, ...initialFiltersState };
    },
    changePage: (state, { payload }) => {
      state.page = payload;
    },
    clearAllSlotsState: (state) => initialState,
    
  },
  extraReducers: {
    [getAllSlots.pending]: (state) => {
      state.isLoading = true;
    },
    [getAllSlots.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
        state.slots = payload.slots;
    //  state.numOfPages = payload.numOfPages;
    //  state.totalSlots = payload.totalSlots;
    },
    [getAllSlots.rejected]: (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload);
      },
    [getAllocations.pending]: (state) => {
        state.isLoading = true;
    },
     [getAllocations.fulfilled]: (state, { payload }) => {
        state.isLoading = false;
         state.allocations = payload.allocations;
        // console.log(state.allocations['1']);
        //  state.numOfPages = payload.numOfPages;
        //  state.totalSlots = payload.totalSlots;
    },
     [getAllocations.rejected]: (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      },
    [getSlotsByDepartment.pending]: (state) => {
        state.isLoading = true;
    },
    [getSlotsByDepartment.fulfilled]: (state, { payload }) => {
        state.isLoading = false;
        state.slots = payload.slots;
        //  state.numOfPages = payload.numOfPages;
        //  state.totalSlots = payload.totalSlots;
    },
    [getSlotsByDepartment.rejected]: (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      },
      [getAllocationsByDepartment.pending]: (state) => {
          state.isLoading = true;
      },
      [getAllocationsByDepartment.fulfilled]: (state, { payload }) => {
          state.isLoading = false;
          state.allocations = payload.allocations;
          // console.log(state.allocations['1']);
          //  state.numOfPages = payload.numOfPages;
          //  state.totalSlots = payload.totalSlots;
      },
      [getAllocationsByDepartment.rejected]: (state, { payload }) => {
          state.isLoading = false;
          toast.error(payload);
      },
    [showStats.pending]: (state) => {
      state.isLoading = true;
    },
    [showStats.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.stats = payload.defaultStats;
      state.monthlyApplications = payload.monthlyApplications;
    },
    [showStats.rejected]: (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload);
    },
  },
});


export const {
  showLoading,
  hideLoading,
  handleChange,
  clearFilters,
  changePage,
  clearAllSlotsState,
} = allSlotsSlice.actions;

export default allSlotsSlice.reducer;
