import { UsersContainer } from '../../components';
import { useSelector } from 'react-redux';
import { AccessDenied } from "../";


const AllUsers = () => {
    const { user, isLoading } = useSelector(store => store.user)

    if (user.role_Id == "Admin") {
        return (
            <>
                <UsersContainer />
            </>
        );
    }
    else {
        return (
            <>
                <AccessDenied />
            </>
        );
    }
};
export default AllUsers;
