import { useEffect, useState } from 'react';
import Department from '../Department';
import Wrapper from '../../../assets/wrappers/DepartmentsContainer';
import { useSelector, useDispatch } from 'react-redux';
import Loading from '../../Loading';
import { getAllDepartments } from '../../../features/allDepartments/allDepartmentsSlice';
import AllDepartmentsPageBtnContainer from './AllDepartmentsPageBtnContainer';
import Modal from '../../Modal';
import { useGlobalContext } from '../../context';
import { DepartmentInfoContainer } from '../../';
import { clearValues } from '../../../features/department/departmentSlice';
import { deleteDepartment } from '../../../features/department/departmentSlice';
import swal from 'sweetalert';


const DepartmentsContainer = () => {
  const {
    departments,
    areDepartmentsLoading,
    page,
    totalDepartments,
    numOfPages,
    search,
  } = useSelector((store) => store.allDepartments);

    const { user } = useSelector(store => store.user);

  const dispatch = useDispatch();
  const { openSidebar, openModal } = useGlobalContext();
  const openModalAux = () => {
    dispatch(clearValues());
    openModal();
  };

  useEffect(() => {
      dispatch(getAllDepartments());

  }, [page, search]);

  if (areDepartmentsLoading) {
    return <Loading />;
  }

  if (departments.length === 0) {
    return (
        <Wrapper>
            {user.role_Id == "Admin" ?
                <button onClick={openModalAux} className='btn'>
                    Agregar departamento
                </button>
                :
                ""
            }
        <Modal>
            <DepartmentInfoContainer />
        </Modal>
            <h5></h5>
        <h2>No hay departamentos para mostrar...</h2>
    </Wrapper>
    );
  }

    const onDeleteDepartment = (departmentRecId, departmentName) => {

        swal({
            title: "\u00BF Seguro deseas eliminar el departamento " + departmentName + "?",
            text: "Una vez eliminado, no ser\u00E1 posible recuperar la informaci\u00F3n",
            icon: "warning",
            buttons: {
                cancel: "Cancelar",
                confirm: "Eliminar",
            },
            dangerMode: true,
        })
        .then((willDelete) => {
            if (willDelete) {
                dispatch(deleteDepartment(departmentRecId));
            }
        });
    }

  return (
    <Wrapper>
      <h5>
        {totalDepartments} departamento{departments.length > 1 && 's'} encontrado{departments.length > 1 && 's'}
      </h5>
      
          <div className='departments'>

              {user.role_Id == "Admin" ?
                  <button onClick={openModalAux} className='btn'>
                      Agregar departamento
                  </button>
                  :
                  ""
              }
        <Modal>
          <DepartmentInfoContainer/>
        </Modal>
        {departments.map((department) => {
            return <Department key={department.rec_Id} onDelete={user.role_Id == "Admin" ? onDeleteDepartment : null} {...department} />;
        })}
      </div>
      {numOfPages > 1 && <AllDepartmentsPageBtnContainer />}
    </Wrapper>
  );
};
export default DepartmentsContainer;
