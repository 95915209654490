import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Loading from '../Loading';
import Wrapper from '../../assets/wrappers/Calendar';
import { MdAddCircleOutline } from 'react-icons/md';
import { FaTrash } from 'react-icons/fa';
import { getAllocationsByDepartment, getSlotsByDepartment } from '../../features/allSlots/allSlotsSlice';
import { getAllWeekDays } from '../../features/allWeekDays/allWeekDaysSlice';
import SelectAsyncPaginateEmpAllocation from './SelectAsyncPaginateEmpAllocation';
import { useGlobalContext } from '../context';
import Modal from '../Modal';
import { getNotIncludedSlotDayEmployeesDep } from '../../features/allEmployees/allEmployeesSlice';
import { addEmployeesToSlot, deleteSlotEmployee } from '../../features/slot/slotSlice';
import { toast } from "react-toastify";


const CalendarContainer = () => {
    const {
        allocations,
        slots,
        isLoading,
        searchFloor,
        searchDepartment,
    } = useSelector((store) => store.allSlots);

    const {
        weekDays
    } = useSelector((store) => store.allWeekDays);

    const { user } = useSelector(store => store.user)

    const { isModalOpen, openModal, closeModal } = useGlobalContext();
    const dispatch = useDispatch();
    const [allocationKey, setAllocationKey] = useState({});
    const [selectedEmployees, setSelectedEmployees] = useState([]);


    const addEmployee = (slotDay) => {
        setAllocationKey(slotDay);
        openModal();
    };

    const deleteEmployee = (record) => {
        dispatch(deleteSlotEmployee(record)).then(() => {
            dispatch(getAllocationsByDepartment());
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (selectedEmployees.length === 0) {
            toast.error("Por favor llenar todos los datos.");
            return;
        }
        closeModal();

        dispatch(addEmployeesToSlot({
            slotRecId: allocationKey.slotRecId,
            dayRecId: allocationKey.dayRecId,
            employees: selectedEmployees,
            fromCalendar: true
        })).then(() => {
            dispatch(getAllocationsByDepartment());
        });
    }


    useEffect(() => {
        dispatch(getAllWeekDays());
    }, []);

    useEffect(() => {
        dispatch(getSlotsByDepartment()).then(() => {
            dispatch(getAllocationsByDepartment());
        });
    }, [searchFloor, searchDepartment]);

    if (isLoading || weekDays.length == 0) {
        return <Loading />;
    }


    return (
        <Wrapper>
            <Modal>
                <form className="form">
                    <h3>{'Agregar colaboradores'}</h3>

                    <div className="form-center">
                        <SelectAsyncPaginateEmpAllocation
                            value={selectedEmployees}
                            onChange={setSelectedEmployees}
                            methodAPI={getNotIncludedSlotDayEmployeesDep}
                            allocationKey={allocationKey}
                        />
                        <button
                            className='btn btn-block submit-btn'
                            onClick={handleSubmit}
                            disabled={selectedEmployees.length === 0}
                        >
                            Agregar
                        </button>
                    </div>
                </form>
            </Modal>

            <div >
                <table>
                    <thead>
                        <tr>
                            <th className="table-header">Espacios</th>
                            {weekDays.map(day => (
                                <th key={day.rec_Id} className="table-header">{day.name}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {slots.map((slot) => (
                            <tr key={slot.rec_Id}>
                                <th className="table-cell-slot">{slot.slot_Id}</th>
                                {weekDays.map(day => (
                                    <td key={day.rec_Id} className="table-cell">
                                        <ul>
                                        {allocations[day.rec_Id] && allocations[day.rec_Id][slot.rec_Id] ? allocations[day.rec_Id][slot.rec_Id].map((employee, empIndex) => (
                                            <li className="employee" key={"itemEmp" + empIndex} >
                                                {employee.person_Name}
                                                <FaTrash color="red" onClick={() => deleteEmployee({ slotRecId: slot.rec_Id, dayRecId: day.rec_Id, employeeRecId: employee.person_Rec_Id }) } />
                                            </li>
                                        )) : ''}
                                        </ul>
                                        {(allocations[day.rec_Id] && !allocations[day.rec_Id][slot.rec_Id]) || !allocations[day.rec_Id] ?
                                            <MdAddCircleOutline color="green" onClick={() => addEmployee({ slotRecId: slot.rec_Id, dayRecId: day.rec_Id, departmentRecId: searchDepartment })} />
                                            : ""
                                        }
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>

                        </tr>
                    </tfoot>
                </table>
            </div>

        </Wrapper>
    );
};

export default CalendarContainer;
