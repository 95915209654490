import { FaLocationArrow, FaAt, FaCalendarAlt, FaPhone, FaIdCard } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Wrapper from '../../assets/wrappers/Floor';
import { useDispatch, useSelector } from 'react-redux';
import FloorInfo from './FloorInfo';
import moment from 'moment';
import { deleteFloor, setEditFloor } from '../../features/floor/floorSlice';
import swal from 'sweetalert';

const Floor = ({
    rec_Id,
    floor_Id,
    createdDateTime,
    createdBy,
    updatedDateTime,
    updatedBy
}) => {
  const dispatch = useDispatch();

    const date = moment(createdDateTime).format('MMM Do, YYYY');

    const onDeleteFloor = () => {
        swal({
            title: "\u00BF Seguro deseas eliminar el " + floor_Id + "?",
            text: "Una vez eliminado, no ser\u00E1 posible recuperar la informaci\u00F3n",
            icon: "warning",
            buttons: {
                cancel: "Cancelar",
                confirm: "Eliminar",
            },
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    dispatch(deleteFloor(rec_Id));
                }
            });

    }

  return (
    <Wrapper>
      <header>
        <div className='main-icon'>{rec_Id}</div>
        <div className='info'>
          <h5></h5>
          <p>{floor_Id}</p>
        </div>
      </header>
      <div className='content'>
        <div className='content-center'>
          <FloorInfo icon={<FaIdCard />} text={createdBy} />
          <FloorInfo icon={<FaCalendarAlt />} text={date} />
        </div>
        <footer>
          <div className='actions'>
            <Link
              to='/edit-floor'
              className='btn edit-btn'
              onClick={() => 
                dispatch(
                  setEditFloor({
                    editFloorRecId: rec_Id,
                    floor_Id,
                    createdDateTime,
                    createdBy,
                    updatedDateTime,
                    updatedBy
                  })
                )
              }
            >
              Editar
            </Link>
            <button
              type='button'
              className='btn delete-btn'
              onClick={onDeleteFloor}
            >
              Eliminar
            </button>
          </div>
        </footer>
      </div>
    </Wrapper>
  );
};
export default Floor;
