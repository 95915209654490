import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { getAllFloorsThunk, showStatsThunk } from './allFloorsThunk';

const initialFiltersState = {
  search: '',
 /* searchStatus: 'all',
  searchType: 'all',
  sort: 'latest',
  sortOptions: ['latest', 'oldest', 'a-z', 'z-a'],*/
};

const initialState = {
  areFloorsLoading: true,
  floors: [],
  totalFloors: 0,
  numOfPages: 1,
  page: 1,
  stats: {},
  ...initialFiltersState,
};

export const getAllFloors = createAsyncThunk('allFloors/getFloors', getAllFloorsThunk);

export const showStats = createAsyncThunk('allFloors/showStats', showStatsThunk);

const allFloorsSlice = createSlice({
  name: 'allFloors',
  initialState,
  reducers: {
    showLoading: (state) => {
      state.areFloorsLoading = true;
    },
    hideLoading: (state) => {
      state.areFloorsLoading = false;
    },
    handleChange: (state, { payload: { name, value } }) => {
      state.page = 1;
      state[name] = value;
    },
    clearFilters: (state) => {
      return { ...state, ...initialFiltersState };
    },
    changePage: (state, { payload }) => {
      state.page = payload;
    },
    clearAllFloorsState: (state) => initialState,
    
  },
  extraReducers: {
    [getAllFloors.pending]: (state) => {
      state.areFloorsLoading = true;
    },
    [getAllFloors.fulfilled]: (state, { payload }) => {
      state.areFloorsLoading = false;
      state.floors = payload.floors;
      //state.numOfPages = payload.numOfPages;
      state.totalFloors = payload.totalFloors;
    },
    [getAllFloors.rejected]: (state, { payload }) => {
      state.areFloorsLoading = false;
      toast.error(payload);
    },
    [showStats.pending]: (state) => {
      state.areFloorsLoading = true;
    },
    [showStats.fulfilled]: (state, { payload }) => {
      state.areFloorsLoading = false;
      state.stats = payload.defaultStats;
      state.monthlyApplications = payload.monthlyApplications;
    },
    [showStats.rejected]: (state, { payload }) => {
      state.areFloorsLoading = false;
      toast.error(payload);
    },
  },
});


export const {
  showLoading,
  hideLoading,
  handleChange,
  clearFilters,
  changePage,
  clearAllFloorsState,
} = allFloorsSlice.actions;

export default allFloorsSlice.reducer;
