import { SlotInfoContainer, SlotDepartmentsContainer, SearchContainerSlotDepartments, SlotEmployeesContainer, SearchContainerSlotEmployees} from '../../components';
import { AppProvider } from '../../components/context';


const SlotInfo = () => {
  return (
    <>
          <SlotInfoContainer />
          <SearchContainerSlotDepartments />
          <AppProvider><SlotDepartmentsContainer /></AppProvider>
    </>
  );
};
export default SlotInfo;
