import { SlotsContainer, SearchContainerSlots } from '../../components';



const AllSlots = () => {
  
  return (
    <>

      <SearchContainerSlots title="Espacios"/>
      <SlotsContainer />
    </>
  );
};
export default AllSlots;