import { useEffect } from 'react';
import User from '../User';
import Wrapper from '../../../assets/wrappers/UsersContainer';
import { useSelector, useDispatch } from 'react-redux';
import Loading from '../../Loading';
import { getAllUsers } from '../../../features/allUsers/allUsersSlice';
import AllUsersPageBtnContainer from './AllUsersPageBtnContainer';
import Modal from '../../Modal';
import { useGlobalContext } from '../../context';
import { UserInfoContainer } from '../../';
import { clearValues } from '../../../features/user/userSlice';



const UsersContainer = () => {
  const {
    users,
    areUsersLoading,
    page,
    totalUsers,
    numOfPages,
    search,
  } = useSelector((store) => store.allUsers);
  const dispatch = useDispatch();
  const { openSidebar, openModal } = useGlobalContext();
  const openModalAux = () => {
   // dispatch(clearValues());
    openModal();
  };


  useEffect(() => {
    dispatch(getAllUsers());
  }, [page, search]);

  if (areUsersLoading) {
    return <Loading />;
  }

  if (users.length === 0) {
    return (
    <Wrapper>
        <button onClick={openModalAux} className='btn'>
            Agregar usuario
        </button>
        <Modal>
            <UserInfoContainer />
        </Modal>
        <h5></h5>
        <h2>No hay usuarios para mostrar...</h2>
    </Wrapper>
    );
  }

  return (
    <Wrapper>
      <h5>
              {/*  {totalUsers} usuario{users.length > 1 && 's'} encontrado{users.length > 1 && 's'}*/}
      </h5>
      
      <div className='users'>
      <button onClick={openModalAux} className='btn'>
          Agregar usuario
        </button>
        <Modal>
          <UserInfoContainer/>
        </Modal>
        {users.map((user) => {
          return <User key={user.rec_Id} {...user} />;
        })}
      </div>
      {numOfPages > 1 && <AllUsersPageBtnContainer />}
    </Wrapper>
  );
};
export default UsersContainer;
