import { useEffect  } from 'react';
import { FormRow, FormRowSelect } from '../../';
import Wrapper from '../../../assets/wrappers/SearchContainer';
import { useSelector, useDispatch } from 'react-redux';
import { handleChange, clearFilters } from '../../../features/allSlots/allSlotsSlice';
import { getAllFloors } from '../../../features/allFloors/allFloorsSlice';
import { getAllDepartments, getDepartmentsRelatedWithUser } from '../../../features/allDepartments/allDepartmentsSlice';
import Loading from '../../Loading';

const SearchContainerSlots = ({ filterByDepartment, title }) => {

    var { searchFloor, searchDepartment } =
        useSelector((store) => store.allSlots);
    const { user, isLoading } = useSelector(store => store.user);

    const dispatch = useDispatch();

    const { floors, areFloorsLoading } = useSelector((store) => store.allFloors);
    const floor_list = floors.map((floor) => {
        return { label: floor.floor_Id, value: floor.rec_Id };
    });

    const { departments, areDepartmentsLoading } = useSelector((store) => store.allDepartments);
    const department_list = departments.map((department) => {
        return { label: department.name, value: department.rec_Id };
    });



    useEffect(() => {
        dispatch(getAllFloors());
        if (user.role_Id == "Secretary") {
            dispatch(getDepartmentsRelatedWithUser(user.rec_Id)).then((response) => {
                if (response.payload.departments)
                    dispatch(handleChange({ name: 'searchDepartment', value: response.payload.departments.length > 0 ? response.payload.departments[0].rec_Id : 0 }));
            });
        }
        else if (filterByDepartment) {
            dispatch(getAllDepartments(true)).then((response) => {
                if (response.payload.departments)
                    dispatch(handleChange({ name: 'searchDepartment', value: response.payload.departments.length > 0 ? response.payload.departments[0].rec_Id : 0 }));
            });
        }
    }, []);


    const handleSearch = (e) => {

        if (areFloorsLoading || ((filterByDepartment || user.role_Id == "Secretary") && areDepartmentsLoading)) return;
        dispatch(handleChange({ name: e.target.name, value: e.target.value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(clearFilters());
    };



    return (
    <Wrapper>
        <form className='form'>
        <h3>{title}</h3>
        <div className='form-center'>
            {/* search by floor*/}
            <FormRowSelect
                labelText='Piso'
                name='searchFloor'
                value={searchFloor}
                handleChange={handleSearch}
                list={floor_list}
            />

            {/* search by dep*/}
            {(user.role_Id == "Secretary" || filterByDepartment) ?
                <FormRowSelect
                    labelText='Departamento'
                    name='searchDepartment'
                    value={searchDepartment}
                    handleChange={handleSearch}
                    list={department_list}
                />
                : ""
            }
        </div>
        </form>
    </Wrapper>
    );
};
export default SearchContainerSlots;
