import { DepartmentInfoContainer, SearchContainerDepartmentEmployees, DepartmentEmployeesContainer} from '../../components';
import { AppProvider } from '../../components/context';


const DepartmentInfo = () => {
  return (
    <>
          <DepartmentInfoContainer />
          <SearchContainerDepartmentEmployees />
          <AppProvider><DepartmentEmployeesContainer /></AppProvider>
    </>
  );
};
export default DepartmentInfo;
