import { FaLocationArrow, FaAt, FaCalendarAlt, FaPhone, FaIdCard } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Wrapper from '../../assets/wrappers/Employee';
import { useDispatch, useSelector } from 'react-redux';
import EmployeeInfo from './EmployeeInfo';
import moment from 'moment';
import { deleteEmployee, setEditEmployee } from '../../features/employee/employeeSlice';

const Employee = ({
    rec_Id,
    badge_Id,
    firstName,
    lastName,
    email,
    person_Id,
    updatedDateTime,
    createdDateTime,
    createdBy,
    updatedBy,
    onDelete,
}) => {
    const dispatch = useDispatch();

    const date = moment(createdDateTime).format('MMM Do, YYYY');

    const deleteRecord = () => {
        // if (typeof props.onChange === "function") {
        onDelete(rec_Id, firstName + " " + lastName);

    };

    const { user } = useSelector(store => store.user);


    return (
    <Wrapper>
        <header>
        <div className='main-icon'>{rec_Id}</div>
        <div className='info'>
            <h5>{firstName + ' ' + lastName}</h5>
            <p>{person_Id}</p>
        </div>
        </header>
        <div className='content'>
        <div className='content-center'>
        </div>
        <footer>
            <div className='actions'>
            <Link
                to='/edit-employee'
                className='btn edit-btn'
                onClick={() => 
                dispatch(
                    setEditEmployee({
                    editRecId: rec_Id,
                    badge_Id,
                    firstName,
                    lastName,
                    email,
                    person_Id,
                    updatedDateTime,
                    createdDateTime,
                    createdBy,
                    updatedBy
                    })
                )
                }
            >
                {user.role_Id == "Admin" ? "Editar" : "Ver"}
            </Link>

            {onDelete ?
                <button
                    type='button'
                    className='btn delete-btn'
                    onClick={deleteRecord}
                >
                    Eliminar
                </button>
                :
                ""
            }

            </div>
        </footer>
        </div>
    </Wrapper>
    );
};
export default Employee;
