import { FormRow, FormRowSelect } from '../..';
import Wrapper from '../../../assets/wrappers/SearchContainer';
import { useSelector, useDispatch } from 'react-redux';
import { handleDepartmentsChange, clearDepartmentsFilters } from '../../../features/slot/slotSlice';

const SearchContainer = () => {
    const { areDepartmentsLoading, departmentSearch } =
        useSelector((store) => store.slot);

    //const { jobTypeOptions, statusOptions } = useSelector((store) => store.slot);

    const dispatch = useDispatch();

    const handleSearch = (e) => {
        if (areDepartmentsLoading) return;
        dispatch(handleDepartmentsChange({ name: e.target.name, value: e.target.value }));
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(clearDepartmentsFilters());
    };

    return (
        <Wrapper>
            <form className='form'>
                <h3>Departamentos asignados</h3>
                <div className='form-center'>
                    {/* search position */}
                    <FormRow
                        type='text'
                        name='departmentSearch'
                        value={departmentSearch}
                        handleChange={handleSearch}
                        labelText="Buscar"
                        placeholder="Nombre..."
                    />

                </div>
            </form>
        </Wrapper>
    );
};
export default SearchContainer;
