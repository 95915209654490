import { FormRow, FormRowSelect } from '../..';
import Wrapper from '../../../assets/wrappers/SearchContainer';
import { useSelector, useDispatch } from 'react-redux';
import { handleEmployeesChange, clearEmployeesFilters } from '../../../features/department/departmentSlice';

const SearchContainer = () => {
    const { areEmployeesLoading, employeeSearch } =
        useSelector((store) => store.department);

    //const { jobTypeOptions, statusOptions } = useSelector((store) => store.department);

    const dispatch = useDispatch();

    const handleSearch = (e) => {
        if (areEmployeesLoading) return;
        dispatch(handleEmployeesChange({ name: e.target.name, value: e.target.value }));
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(clearEmployeesFilters());
    };

    return (
        <Wrapper>
            <form className='form'>
                <h3>Colaboradores asignados</h3>
                <div className='form-center'>
                    {/* search position */}
                    <FormRow
                        type='text'
                        name='employeeSearch'
                        value={employeeSearch}
                        handleChange={handleSearch}
                        labelText="Buscar"
                        placeholder="Nombre..."
                    />

                </div>
            </form>
        </Wrapper>
    );
};
export default SearchContainer;
