import { FloorInfoContainer, } from '../../components';
import { AppProvider } from '../../components/context';


const FloorInfo = () => {
  return (
    <>
          <FloorInfoContainer />
    </>
  );
};
export default FloorInfo;
