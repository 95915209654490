import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import main from '../assets/images/main.svg';
import Wrapper from '../assets/wrappers/LandingPage';
import { Logo } from '../components';
import { config } from '../Config';
import { useMsal } from "@azure/msal-react";
import { EventType } from "@azure/msal-browser";
import { loginApiRequest } from "../authConfig";
import { loginUser } from "../features/user/userSlice";
import { useNavigate } from "react-router-dom";
import Loading from '../components/Loading';

//const msalInstance = new PublicClientApplication(msalConfig);

const Landing = () => {
    const { instance } = useMsal();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user, isLoading } = useSelector(store => store.user)

    instance.handleRedirectPromise().then((response) => {
        if (response) {
            if (!user && !isLoading)
                dispatch(loginUser({ Email: response.account.username }))
                    .then((payload) => {
                        if (payload.user.rec_Id !== "") {
                            setTimeout(() => {
                                navigate('/');
                            }, 2000);
                        }
                        else {
                            const account = instance.getAllAccounts()[0];
                            const logoutRequest = {
                                account: account,
                                postLogoutRedirectUri: "https://amparking-webapp-d365fo-uat.azurewebsites.net/landing",
                            };
                            instance.logoutRedirect(logoutRequest);
                        }
                    });
        }
    });

    const handleLogin = () => {
        instance.loginRedirect(loginApiRequest);
    }



    useEffect(() => {
        if (user) {
            if (user.rec_Id != 0) {
                setTimeout(() => {
                    navigate('/');
                }, 2000);
            }
            else {
                const account = instance.getAllAccounts()[0];
                const logoutRequest = {
                    account: account,
                    postLogoutRedirectUri: "https://amparking-webapp-d365fo-uat.azurewebsites.net/landing",
                };
                instance.logoutRedirect(logoutRequest);
            }
        }
    }, [user]);


    return (
        <Wrapper>
            <nav>
                <Logo />
            </nav>

            <div className='container page'>
                <div className='info'>
                   <h1>
                        {!isLoading ? "Parqueo " : ""}<span>{!isLoading ? "Auto Mercado" : "Iniciando sesión"}</span>
                    </h1>
                    {!isLoading ?
                        <button className='btn btn-hero' onClick={handleLogin} hidden={isLoading} >Iniciar sesión</button>
                        : ""
                    }
                    
                </div>
                <img src={main} alt='job hunt' className='img main-img' />
            </div>
        </Wrapper>
    );
};



export default Landing;