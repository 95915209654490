import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { getAllUsersThunk, showStatsThunk } from './allUsersThunk';

const initialFiltersState = {
  search: '',
 /* searchStatus: 'all',
  searchType: 'all',
  sort: 'latest',
  sortOptions: ['latest', 'oldest', 'a-z', 'z-a'],*/
};

const initialState = {
  areUsersLoading: true,
  users: [],
  totalUsers: 0,
  numOfPages: 1,
  page: 1,
  stats: {},
  ...initialFiltersState,
};

export const getAllUsers = createAsyncThunk('allUsers/getUsers', getAllUsersThunk);

export const showStats = createAsyncThunk('allUsers/showStats', showStatsThunk);

const allUsersSlice = createSlice({
  name: 'allUsers',
  initialState,
  reducers: {
    showLoading: (state) => {
      state.areUsersLoading = true;
    },
    hideLoading: (state) => {
      state.areUsersLoading = false;
    },
    handleChange: (state, { payload: { name, value } }) => {
      state.page = 1;
      state[name] = value;
    },
    clearFilters: (state) => {
      return { ...state, ...initialFiltersState };
    },
    changePage: (state, { payload }) => {
      state.page = payload;
    },
    clearAllUsersState: (state) => initialState,
    
  },
  extraReducers: {
    [getAllUsers.pending]: (state) => {
      state.areUsersLoading = true;
    },
      [getAllUsers.fulfilled]: (state, { payload }) => {
          state.areUsersLoading = false;
        if (payload.success == false)
            toast.error("Error al comunicarse con la base de datos");
        else {
            state.users = payload.users;
            //state.numOfPages = payload.numOfPages;
            state.totalUsers = payload.totalUsers;
        }
    },
    [getAllUsers.rejected]: (state, { payload }) => {
      state.areUsersLoading = false;
      toast.error(payload);
    },
    [showStats.pending]: (state) => {
      state.areUsersLoading = true;
    },
    [showStats.fulfilled]: (state, { payload }) => {
      state.areUsersLoading = false;
      state.stats = payload.defaultStats;
      state.monthlyApplications = payload.monthlyApplications;
    },
    [showStats.rejected]: (state, { payload }) => {
      state.areUsersLoading = false;
      toast.error(payload);
    },
  },
});


export const {
  showLoading,
  hideLoading,
  handleChange,
  clearFilters,
  changePage,
  clearAllUsersState,
} = allUsersSlice.actions;

export default allUsersSlice.reducer;
