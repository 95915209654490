import {
    SearchContainerEmployeesScanner
} from '../../components';
import { AppProvider } from '../../components/context';


const EmployeeInfo = () => {
  return (
    <>
          <SearchContainerEmployeesScanner/>
    </>
  );
};
export default EmployeeInfo;
