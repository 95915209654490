import { useState, useEffect } from "react";
import validator from "validator";

const FormRow = ({ type, name, value, handleChange, labelText, placeholder, disabled, autoFocus, onlyAlpha }) => {

    const isEmail = (email) =>
        /^[A-Z0-9._%+-]+@automercado.biz$/i.test(email);
      //  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
    const [isEmailValid, setIsEmailValid] = useState(false);



    const handleChangeAux = (e) => { 
        const re = /^[A-Za-z\s]+$/;

       // setInputValue(e.target.value === "" ? e.target.value : re.test(e.target.value) ? e.target.value : "");
        handleChange({ target: { name, value: e.target.value === "" ? e.target.value : re.test(e.target.value) ? e.target.value : (e.target.value.replace(/[^a-z\s]/gi, '')) } });

    }

    const handleNumberChange = (e) => {
        const re = /^[0-9]+$/;

        // setInputValue(e.target.value === "" ? e.target.value : re.test(e.target.value) ? e.target.value : "");
        handleChange({ target: { name, value: e.target.value === "" ? e.target.value : re.test(e.target.value) ? e.target.value : (e.target.value.replace(/[^0-9]/gi, '')) } });
    }

    const handleEmailChange = (e) => {
        handleChange({ target: { name, value: e.target.value } });

        if (!isEmail(e.target.value)){
            setIsEmailValid(false);
        }
        else {
            setIsEmailValid(true);
        }
    }

    return (
        <div className="form-row">
            <label htmlFor={name} className="form-label">
                {labelText || name}
            </label>
            {
                type === 'number' ?
                    <input id={name}
                        type={"text"}
                        name={name}
                        value={value}
                        onChange={handleNumberChange}
                        placeholder={placeholder}
                        className="form-input"
                        disabled={disabled}
                    /> :
                    type === 'email' ? 
                        <input id={name}
                            type="email"
                            name={name}
                            value={value}
                            onChange={handleEmailChange}
                            placeholder={placeholder}
                            className={isEmailValid ? "form-input" : "form-input invalid"}
                            disabled={disabled}
                        /> :
                        type != "textarea" ? <input id={name}
                            type={type}
                            name={name}
                            value={value}
                            onChange={onlyAlpha ? handleChangeAux : handleChange}
                            placeholder={placeholder}
                            className="form-input"
                            disabled={disabled}
                            autoFocus={autoFocus}
                        /> :
                            <textarea className="form-textarea" id={name} name={name} rows="4" cols="5" onChange={handleChange} value={value} />
            }
        </div>
    );
};

export default FormRow;