import { FaLocationArrow, FaAt, FaCalendarAlt, FaPhone, FaIdCard } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Wrapper from '../../assets/wrappers/Slot';
import { useDispatch, useSelector } from 'react-redux';
import SlotInfo from './SlotInfo';
import moment from 'moment';
import { deleteSlot, setEditSlot, editSlot } from '../../features/slot/slotSlice';
import { useRef, useEffect, useCallback, useState} from 'react';
import { createSlot } from "../../features/slot/slotSlice";
import { useGlobalContext } from '../context';
import { useNavigate } from "react-router-dom"
import Car  from "../Car";

const Slot = ({
    rec_Id,
    slot_Id,
    available,
    coordX,
    coordY,
    floor,
    used_By,
    createdDateTime,
    updatedDateTime,
    createdBy,
    updatedBy,
    isNew = false,
    containerRef,
    isEditingRef,
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

/*const handleInputEditing = useCallback(event => {
    onEditingChange(event.target.value)
}, [onEditingChange])*/

 // const date = moment(createddatetime).format('MMM Do, YYYY');

    const [isEdited, setIsEdited] = useState(false);
    const [isSlotNew, setIsSlotNew] = useState(isNew);


    const [isSelected, setIsSelected] = useState(false);
    const { isModalOpen, openModal } = useGlobalContext();

    const boxRef = useRef(null);


    const isClicked = useRef(false);

    const coords = useRef({
        startX: coordX,
        startY: coordY,
        lastX: coordX,
        lastY: coordY
    });

    useEffect(() => {
        if (!boxRef.current /*|| !containerRef.current*/) return;



        const box = boxRef.current;

        if (!isEditingRef.current && (isEdited || isSlotNew)) {
            coords.current.startX =   box.style.left;
            coords.current.startY =  box.style.top;
            coords.current.lastX =  box.style.left;
            coords.current.lastY =  box.style.top;
            setIsEdited(false);
            
            if (isSlotNew)
            {
                dispatch(createSlot({ slot_Id, floor, coordX: parseFloat(coords.current.lastX), coordY: parseFloat(coords.current.lastY) })).then(
                    ({ payload }) => {
                        if (payload) {
                            rec_Id = payload.rec_Id;
                        }
                    }
                );
                setIsSlotNew(false);
            }
            else if (isEdited)
            {
                dispatch(editSlot({ slotRecId: rec_Id, slot: { slot_Id, available, coordX: parseFloat(coords.current.lastX), coordY: parseFloat(coords.current.lastY) } }));
                setIsEdited(false);
            }
        }

        box.style.top = `${coords.current.lastY}px`;
        box.style.left = `${coords.current.lastX}px`;

        const onMouseDown = (e: MouseEvent) => {

            isClicked.current = true;
            setIsSelected(true);
            coords.current.startX = e.clientX;
            coords.current.startY = e.clientY;

        }

        const onMouseUp = (e: MouseEvent) => {
           

            if (!isEditingRef.current && isClicked.current===true && rec_Id) {
                dispatch(
                    setEditSlot({
                        editSlotRecId: rec_Id,
                        slot_Id,
                        coordX: parseFloat(coords.current.lastX),
                        coordY: parseFloat(coords.current.lastY),
                        floor,
                        available,
                        used_By,
                        createdDateTime,
                        updatedDateTime,
                        createdBy,
                        updatedBy,
                    })
                );

                navigate('/edit-slot');
            }

            isClicked.current = false;
            setIsSelected(false);
            coords.current.lastX = box.offsetLeft;
            coords.current.lastY = box.offsetTop;

            
        }

        const onMouseMove = (e: MouseEvent) => {

            if (!isClicked.current || !isEditingRef.current) return;

            const nextX = e.clientX - coords.current.startX + coords.current.lastX;
            const nextY = e.clientY - coords.current.startY + coords.current.lastY;

            if((nextX + box.clientWidth) > containerRef.current.clientWidth || (nextY + box.clientHeight) > containerRef.current.clientHeight || nextY < 0 || nextX < 0)
                return;

            box.style.top = `${nextY}px`;
            box.style.left = `${nextX}px`;

            

            setIsEdited(true);

        }

        box.addEventListener('mousedown', onMouseDown);
        box.addEventListener('mouseup', onMouseUp);
        box.addEventListener('mousemove', onMouseMove);
        box.addEventListener('mouseleave', onMouseUp);

        const cleanup = () => {
            box.removeEventListener('mousedown', onMouseDown);
            box.removeEventListener('mouseup', onMouseUp);
            box.removeEventListener('mousemove', onMouseMove);
            box.removeEventListener('mouseleave', onMouseUp);
        }



        return cleanup;
    }, [isEditingRef.current])



  return (
      <Wrapper>

          <div ref={boxRef}
              className={isSelected ? "slotBox selected" : available ? "slotBox-Available" : "slotBox-Occupied"}>
              {available ? <div></div>: (<div><h4></h4><Car /></div>)}
              <p className="p-slotBox">{slot_Id}</p>
        </div>
    </Wrapper>
  );
};
export default Slot;

/*
<Wrapper>
      <header>
        <div className='main-icon'>{recid}</div>
        <div className='info'>
          <h5>{slot_id}</h5>
          <p>{slot_id}</p>
        </div>
      </header>
      <div className='content'>
        <div className='content-center'>
          <SlotInfo icon={<FaIdCard />} text={slot_id} />
          <SlotInfo icon={<FaAt />} text={description} />
        </div>
        <footer>
          <div className='actions'>
            <Link
              to='/edit-slot'
              className='btn edit-btn'
              onClick={() => 
                dispatch(
                  setEditSlot({
                    editSlotId: recid,
                    slot_id,
                    description,
                    createddatetime,
                    createdby,
                  })
                )
              }
            >
              Editar
            </Link>
            <button
              type='button'
              className='btn delete-btn'
              onClick={() => dispatch(deleteSlot(recid))}
            >
              Eliminar
            </button>
          </div>
        </footer>
      </div>
    </Wrapper>
*/
