import { showLoading, hideLoading, getAllSlots,  getAllocationsByDepartment} from '../allSlots/allSlotsSlice';
import customFetch, { checkForUnauthorizedResponse } from '../../utils/axios';
import { clearValues, getSlotDepartments, getSlotEmployees } from './slotSlice';

export const createSlotThunk = async (slot, thunkAPI) => {
    try {
        const resp = await customFetch.post('/Slot', JSON.stringify(slot), {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        //thunkAPI.dispatch(getAllSlots());
        return resp.data;
    }
    catch (error) {
        return checkForUnauthorizedResponse(error, thunkAPI);
    }
};

export const deleteSlotThunk = async (slotRecId, thunkAPI) => {
    thunkAPI.dispatch(showLoading());
    try {
        const resp = await customFetch.delete(`/Slot/${slotRecId}`);
        thunkAPI.dispatch(getAllSlots());
        return resp.data.msg;
    } catch (error) {
        thunkAPI.dispatch(hideLoading());
        return checkForUnauthorizedResponse(error, thunkAPI);
    }
};
export const editSlotThunk = async ({ slotRecId, slot }, thunkAPI) => {
    try {
        const resp = await customFetch.patch(`/Slot/${slotRecId}`, slot);
        //thunkAPI.dispatch(clearValues());
        return resp.data;
  } catch (error) {
        return checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const getSlotDepartmentsThunk = async (slotRecId, thunkAPI) => {

    const { departmentsPage, departmentSearch, departmentsLimit } =
        thunkAPI.getState().slot;

    if (!departmentsLimit)
        departmentsLimit = 10;

    let url = `/Slot/Departments/${slotRecId}?page=${departmentsPage}&limit=${departmentsLimit}`;
    if (departmentSearch) {
        url = url + `&search=${departmentSearch}`;
    }
    else {
        url = url + `&search=""`;
    }

    try {
        const resp = await customFetch.get(url);
        return resp.data;
    } catch (error) {
        return checkForUnauthorizedResponse(error, thunkAPI);
    }
};


export const addDepartmentsToSlotThunk = async ({ slotRecId, departments }, thunkAPI) => {
    try {
        const resp = await customFetch.patch(`/Slot/Departments/AddDepartments/${slotRecId}`, departments);
        thunkAPI.dispatch(getSlotDepartments(slotRecId));
        return resp.data;
    } catch (error) {
        return checkForUnauthorizedResponse(error, thunkAPI);
    }
};

export const getSlotEmployeesThunk = async (slotRecId, thunkAPI) => {

    const { employeesPage, employeeSearch, employeesLimit } =
        thunkAPI.getState().slot;

    if (!employeesLimit)
        employeesLimit = 10;

    let url = `/Slot/Employees/${slotRecId}?page=${employeesPage}&limit=${employeesLimit}`;
    if (employeeSearch) {
        url = url + `&search=${employeeSearch}`;
    }
    else {
        url = url + `&search=""`;
    }

    try {
        const resp = await customFetch.get(url);
        return resp.data;
    } catch (error) {
        return checkForUnauthorizedResponse(error, thunkAPI);
    }
};


export const addEmployeesToSlotThunk = async ({ slotRecId, dayRecId, employees, fromCalendar }, thunkAPI) => {
    try {
        const resp = await customFetch.patch(`/Slot/Employees/AddEmployees/${slotRecId}?dayRecId=${dayRecId}`, employees);
        if (fromCalendar) {
            thunkAPI.dispatch(getAllocationsByDepartment());
        }
        else {
            thunkAPI.dispatch(getSlotEmployees(slotRecId));
        }

        return resp.data;
    } catch (error) {
        return checkForUnauthorizedResponse(error, thunkAPI);
    }
};

export const deleteSlotDepartmentThunk = async ({ slotRecId, departmentRecId }, thunkAPI) => {
    try {
        const resp = await customFetch.delete(`/Slot/Departments/DeleteDepartment/${slotRecId}?department=${departmentRecId}`);
        thunkAPI.dispatch(getSlotDepartments(slotRecId));
        return resp.data;
    } catch (error) {
        return checkForUnauthorizedResponse(error, thunkAPI);
    }
};

export const deleteSlotEmployeeThunk = async ({ slotRecId, dayRecId, employeeRecId, fromCalendar }, thunkAPI) => {
    try {
        const resp = await customFetch.delete(`/Slot/Employees/DeleteEmployee/${slotRecId}?employee=${employeeRecId}&dayRecId=${dayRecId}`);
        if (fromCalendar) {
            thunkAPI.dispatch(getAllocationsByDepartment());
        }
        else {
            thunkAPI.dispatch(getSlotEmployees(slotRecId));
        }
        return resp.data;
    } catch (error) {
        return checkForUnauthorizedResponse(error, thunkAPI);
    }
};