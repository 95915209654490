import { EmployeesContainer, SearchContainerEmployees } from '../../components';


const AllEmployees = () => {
  return (
    <>
      <SearchContainerEmployees />
      <EmployeesContainer />
    </>
  );
};
export default AllEmployees;
