import { Link } from 'react-router-dom';
import img from '../assets/images/not-found.svg';
import Wrapper from '../assets/wrappers/ErrorPage';

const Error = () => {
    return (
      <Wrapper className='full-page'>
        <div>
          <img src={img} alt='not found' />
          <h3>¡Oh no! Página no encontrada</h3>
          <p>No podemos ver la página que estás buscando.</p>
          <Link to='/'>Volver</Link>
        </div>
      </Wrapper>
    );
  };
  
 export default Error;