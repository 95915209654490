import { useEffect, useState } from 'react';
import Department from '../../department/Department';
import Wrapper from '../../../assets/wrappers/DepartmentsContainer';
import { useSelector, useDispatch } from 'react-redux';
import Loading from '../../Loading';
import { getSlotDepartments, addDepartmentsToSlot, deleteSlotDepartment } from '../../../features/slot/slotSlice';
import SlotDepartmentsPageBtnContainer from './SlotDepartmentsPageBtnContainer';
import SelectAsyncPaginateDepartments from "../../department/allDepartments/SelectAsyncPaginateDepartments";
import Modal from '../../Modal';
import { useGlobalContext } from '../../context';
import { getNotIncludedSlotDepartments, getAllDepartments } from '../../../features/allDepartments/allDepartmentsSlice';
import { toast } from "react-toastify";
import swal from 'sweetalert';

const SlotDepartmentsContainer = () => {
    const {
        departments,
        areDepartmentsLoading,
        departmentsPage,
        totalDepartments,
        numOfDepartmentPages,
        departmentSearch,
        editSlotRecId,
    } = useSelector((store) => store.slot);

    const { user } = useSelector((store) => store.user);


    const dispatch = useDispatch();
    const { isModalOpen, openModal, closeModal } = useGlobalContext();

    const [selectedDepartments, setSelectedDepartments] = useState([]);

    useEffect(() => {
        dispatch(getSlotDepartments(editSlotRecId));
    }, [departmentsPage, departmentSearch]);


    const openModalAux = () => {
        //setSelectedDepartments([...selectedDepartments, ...departments]);
        openModal();

    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (selectedDepartments.length === 0) {
            toast.error("Por favor llenar todos los datos.");
            return;
        }
        closeModal();

        dispatch(addDepartmentsToSlot({
            slotRecId: editSlotRecId,
            departments: selectedDepartments
        }));
    }

    const onDeleteDepartment = (departmentRecId, departmentName) => {
        swal({
            title: "\u00BF Seguro deseas excluir al departamento " + departmentName + " de este espacio?",
            text: "Una vez eliminado, no ser\u00E1 posible recuperar la informaci\u00F3n",
            icon: "warning",
            buttons: {
                cancel: "Cancelar",
                confirm: "Eliminar",
            },
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    dispatch(deleteSlotDepartment({ slotRecId: editSlotRecId, departmentRecId }));
                }
            });
        
    }

    if (areDepartmentsLoading) {
        return <Loading />;
    }

    if (departments.length === 0) {
        return (
            <Wrapper>
                <button onClick={openModalAux} className='btn'>
                    Agregar departamento
                </button>
                <h4></h4>
                <Modal>
                    <form className="form">
                        <h3>{'Agregar departamentos'}</h3>

                        <div className="form-center">
                            <SelectAsyncPaginateDepartments
                                value={selectedDepartments}
                                onChange={setSelectedDepartments}
                                methodAPI={getNotIncludedSlotDepartments}
                                sourceRecId={editSlotRecId}
                            />
                            <button
                                className='btn btn-block submit-btn'
                                onClick={handleSubmit}
                                disabled={selectedDepartments.length === 0}
                            >
                                Agregar
                            </button>
                        </div>
                    </form>
                </Modal>
                <h2>No hay departamentos para mostrar...</h2>
            </Wrapper>
        );
    }



    return (
        <Wrapper>
            <h5>
                {totalDepartments} departamento{departments.length > 1 && 's'} encontrado{departments.length > 1 && 's'}
            </h5>
            <div className='departments'>
                <button onClick={openModalAux} className='btn'>
                    Agregar departamento
                </button>
                <Modal>
                    <form className="form">
                        <h3>{'Agregar departamentos'}</h3>

                        <div className="form-center">
                            <SelectAsyncPaginateDepartments
                                value={selectedDepartments}
                                onChange={setSelectedDepartments}
                                methodAPI={getNotIncludedSlotDepartments}
                                sourceRecId={editSlotRecId}
                            />
                            <button
                                className='btn btn-block submit-btn'
                                onClick={handleSubmit}
                                disabled={selectedDepartments.length === 0}
                            >
                                Agregar
                            </button>
                        </div>
                    </form>
                </Modal>
                {departments.map((department) => {
                    if (user.role_Id == "Admin") {
                        return <Department key={department.rec_Id} onDelete={onDeleteDepartment} {...department} />;
                    }
                    else {
                        return <Department key={department.rec_Id} {...department} />;
                    }
                })}
            </div>
            {numOfDepartmentPages > 1 && <SlotDepartmentsPageBtnContainer />}
        </Wrapper>
    );
};
export default SlotDepartmentsContainer;
