import { FormRow, FormRowSelect, FormRowInfo } from '../';
import Wrapper from '../../assets/wrappers/ScannerContainer';
import { useSelector, useDispatch } from 'react-redux';
import { handleChange, clearFilters } from '../../features/allEmployees/allEmployeesSlice';
import { getEmployeeInfo, assignEmployeeSlot } from '../../features/employee/employeeSlice';
import { useState, useEffect } from 'react';
import { getAllWeekDays } from '../../features/allWeekDays/allWeekDaysSlice';
import swal from '@sweetalert/with-react'


const SearchContainer = () => {

    const date = new Date();
  const { person_Id, firstName, lastName, slotAssigned } =
      useSelector((store) => store.employee);

    const [scannedValue, setScannedValue] = useState("");


    const [isManualEntry, setIsManualEntry] = useState(false);

    const {
        weekDays
    } = useSelector((store) => store.allWeekDays);

  //const { jobTypeOptions, statusOptions } = useSelector((store) => store.customer);

  const dispatch = useDispatch();

    const handleSearch = (e) => {
        setScannedValue(e.target.value);
    };


    const handleCheckboxChange = (event) => {
        setIsManualEntry(event.target.checked);
    };

    const handleScan = () => {
        let msgType = "error";
        let msg = "";
        let subMsg = "";

        if (scannedValue != "") {
            dispatch(getEmployeeInfo({ person_Id: scannedValue, day: (date.getDay()) % 7 })).then((resp) => {
                if (resp.payload) {
                    if (resp.payload.success == true) {
                        if (resp.payload.employee && resp.payload.employee.person_Id) {
                            if (resp.payload.employee.slotAssigned.slot_Id === "") {
                                msg = resp.payload.employee.firstName + ", no tienes un espacio asignado para hoy";
                                subMsg = "";
                            }
                            else {
                                msg = "Bienvenido " + resp.payload.employee.firstName;
                                subMsg = resp.payload.employee.slotAssigned.slotFloor.floor_Id + " - " + resp.payload.employee.slotAssigned.slot_Id;
                                msgType = "success";
                                dispatch(assignEmployeeSlot(resp.payload.employee));
                            }
                        }
                        else {
                            msg = "Colaborador no encontrado";
                        }
                    }
                    else {
                        msg = "Error con obtener información del colaborador";
                    }
                }
                else {
                    msg = "Error al escanear";
                }
                swal({
                    title: msg,
                    //text: subMsg,
                    icon: msgType,
                    buttons: false,
                    timer: 8000,
                    content: (
                        <b><h2>
                            {subMsg}
                        </h2>
                        </b>
                    )
                });
            });
            
        }
        
        setScannedValue("");
    };

    useEffect(() => {
        dispatch(getAllWeekDays());
    }, []);

    useEffect(() => {
        if (!isManualEntry) {
            const delayDebounceFn = setTimeout(() => {
                handleScan();
            }, 1000);

            return () => clearTimeout(delayDebounceFn);
        }
    }, [scannedValue]);

    function handleKeyDown(event) {
        if (event.key === 'Enter') {
            handleScan();
        }
    }

  return (
    <Wrapper>
      <div className='form'>
      <h3>Control de acceso</h3>
        <div className='form-center' onKeyDown={handleKeyDown} >
          {/* search position */}
                  <FormRow
                      type='text'
                      name='search'
                      value={scannedValue}
                      handleChange={handleSearch}
                      labelText="Buscar"
                      placeholder="Identificación de acceso"
                      autoFocus={true}
                  />
                  <div className="form-row">
                      <label  className="form-label">
                          Entrada manual
                      </label>
                      <input
                          type="checkbox"
                          checked={isManualEntry}
                          onChange={handleCheckboxChange}
                      />
                </div>
        </div>
          </div>
          <div className="container">
              <div className="card">
                  <h4 className="card-description" >Cédula</h4>
                  <h2>{person_Id ? person_Id : "-"}</h2>
              </div>
              <div className="card">
                  <h4 className="card-description"  >Colaborador</h4>
                  <h2 >{firstName ? firstName + " " + lastName : "-"}</h2>
              </div>
              <div className="card">
                  <h4 className="card-description"  >Espacio asignado</h4>
                  <h2 style={slotAssigned.slot_Id == "" ? { color: 'red' } : { color: 'green' }} >{slotAssigned.slot_Id == "" ? "Sin espacio" : slotAssigned.slotFloor ? slotAssigned.slotFloor.floor_Id + " - " + slotAssigned.slot_Id : "-"}</h2>
              </div>
          </div>
    </Wrapper>
  );
};
export default SearchContainer;
