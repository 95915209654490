import { FormRow, FormRowSelect } from '../..';
import Wrapper from '../../../assets/wrappers/SearchContainer';
import { useSelector, useDispatch } from 'react-redux';
import { handleChange, clearFilters } from '../../../features/allEmployees/allEmployeesSlice';

const SearchContainer = () => {
  const { isLoading, search} =
    useSelector((store) => store.allEmployees);

  //const { jobTypeOptions, statusOptions } = useSelector((store) => store.customer);

  const dispatch = useDispatch();

  const handleSearch = (e) => {
    if (isLoading) return;
    dispatch(handleChange({ name: e.target.name, value: e.target.value }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(clearFilters());
  };

  return (
    <Wrapper>
      <form className='form'>
      <h3>Colaboradores</h3>
        <div className='form-center'>
          {/* search position */}
          <FormRow
            type='text'
            name='search'
            value={search}
            handleChange={handleSearch}
            labelText="Buscar"
            placeholder="Identificación o nombre..."
          />

        </div>
      </form>
    </Wrapper>
  );
};
export default SearchContainer;
