import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { getUserFromLocalStorage } from '../../utils/localStorage';
import {
    createDepartmentThunk,
    deleteDepartmentThunk,
    editDepartmentThunk,
    getDepartmentSlotsThunk,
    getDepartmentEmployeesThunk,
    addEmployeesToDepartmentThunk,
    deleteDepartmentEmployeeThunk
} from './departmentThunk';

const initialSlotsFiltersState = {
  slotSearch: '',
 /* searchStatus: 'all',
  searchType: 'all',
  sort: 'latest',
  sortOptions: ['latest', 'oldest', 'a-z', 'z-a'],*/
};

const initialEmployeesFiltersState = {
    employeeSearch: '',
    /* searchStatus: 'all',
     searchType: 'all',
     sort: 'latest',
     sortOptions: ['latest', 'oldest', 'a-z', 'z-a'],*/
};


const initialState = {
    isLoading: false,
    name: '',
    createdDateTime: '',
    updatedDateTime: '',
    createdBy: '',
    updatedBy: '',
    isEditing: false,
    editDepartmentRecId: '',
    areSlotsLoading: true,
    slots: [],
    totalSlots: 0,
    numOfSlotPages: 1,
    slotsPage: 1,
    areEmployeesLoading: true,
    employees: [],
    totalEmployees: 0,
    numOfEmployeesPages: 1,
    employeesPage: 1,
    employeesLimit: 10,
    ...initialSlotsFiltersState,
    ...initialEmployeesFiltersState,
};

export const createDepartment = createAsyncThunk('department/createDepartment', createDepartmentThunk);
export const deleteDepartment = createAsyncThunk('department/deleteDepartment', deleteDepartmentThunk);
export const editDepartment = createAsyncThunk('department/editDepartment', editDepartmentThunk);
export const getDepartmentSlots = createAsyncThunk('department/getDepartmentSlots', getDepartmentSlotsThunk);
export const getDepartmentEmployees = createAsyncThunk('department/getDepartmnentEmployees', getDepartmentEmployeesThunk);
export const addEmployeesToDepartment = createAsyncThunk('department/addEmployees', addEmployeesToDepartmentThunk);
export const deleteDepartmentEmployee = createAsyncThunk('department/deleteEmployee', deleteDepartmentEmployeeThunk);




const departmentSlice = createSlice({
  name: 'department',
  initialState,
  reducers: {
    handleChange: (state, { payload: { name, value } }) => {
      state[name] = value;
    },
    clearValues: () => {
      return {
        ...initialState,
        //jobLocation: getUserFromLocalStorage()?.location || '',
      };
    },
    setEditDepartment: (state, { payload }) => {
      return { ...state, isEditing: true, ...payload };
    },
    showSlotsLoading: (state) => {
      state.areSlotsLoading = true;
    },
    hideSlotsLoading: (state) => {
      state.areSlotsLoading = false;
    },
    handleSlotsChange: (state, { payload: { name, value } }) => {
      state.slotsPage = 1;
      state[name] = value;
    },
    clearSlotsFilters: (state) => {
      return { ...state, ...initialSlotsFiltersState };
    },
    changeSlotsPage: (state, { payload }) => {
      state.slotsPage = payload;
      },

      /*employees*/
      showEmployeesLoading: (state) => {
          state.areEmployeesLoading = true;
      },
      hideEmployeesLoading: (state) => {
          state.areEmployeesLoading = false;
      },
      handleEmployeesChange: (state, { payload: { name, value } }) => {
          state.employeesPage = 1;
          state[name] = value;
      },
      clearEmployeesFilters: (state) => {
          return { ...state, ...initialEmployeesFiltersState };
      },
      changeEmployeesPage: (state, { payload }) => {
          state.employeesPage = payload;
      },
  },
  extraReducers: {
    [createDepartment.pending]: (state) => {
      state.isLoading = true;
    },
    [createDepartment.fulfilled]: (state) => {
      state.isLoading = false;
      toast.success('Department Created');
    },
    [createDepartment.rejected]: (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload);
    },
    [deleteDepartment.fulfilled]: (state, { payload }) => {
      toast.success(payload);
    },
    [deleteDepartment.rejected]: (state, { payload }) => {
      toast.error(payload);
    },
    [editDepartment.pending]: (state) => {
      state.isLoading = true;
    },
    [editDepartment.fulfilled]: (state) => {
      state.isLoading = false;
      toast.success('Departamento modificado...');
    },
    [editDepartment.rejected]: (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload);
    },
    [getDepartmentSlots.pending]: (state) => {
      
      state.areSlotsLoading = true;
      },

      /*EMPLOYEES*/
      [getDepartmentEmployees.pending]: (state) => {
          state.areEmployeesLoading = true;
      },
      [getDepartmentEmployees.fulfilled]: (state, { payload }) => {
          state.areEmployeesLoading = false;
          state.employees = payload.employees;
          state.numOfEmployeesPages = payload.numOfPages;
          state.totalEmployees = payload.totalEmployees;
      },
      [getDepartmentEmployees.rejected]: (state, { payload }) => {
          state.areEmployeesLoading = false;
          toast.error(payload);
      },
      [addEmployeesToDepartment.pending]: (state) => {
          state.areEmployeesLoading = true;
      },
      [addEmployeesToDepartment.fulfilled]: (state) => {
          state.areEmployeesLoading = false;
          toast.success('Colaboradores agregados');
      },
      [addEmployeesToDepartment.rejected]: (state, { payload }) => {
          state.areEmployeesLoading = false;
          toast.error(payload);
      },
      [deleteDepartmentEmployee.pending]: (state) => {
          state.areEmployeesLoading = true;
      },
      [deleteDepartmentEmployee.fulfilled]: (state) => {
          state.areEmployeesLoading = false;
          toast.success('Colaborador eliminado');
      },
      [deleteDepartmentEmployee.rejected]: (state, { payload }) => {
          state.areEmployeesLoading = false;
          toast.error(payload);
      },

    [getDepartmentSlots.fulfilled]: (state, { payload }) => {
      state.areSlotsLoading = false;
      state.slots = payload.slots;
      state.numOfSlotPages = payload.numOfPages;
      state.totalSlots = payload.totalSlots;
    },
    [getDepartmentSlots.rejected]: (state, { payload }) => {
      state.areSlotsLoading = false;
      toast.error(payload);
    },
  },
});

export const { 
    handleChange, 
    clearValues, 
    setEditDepartment,
    showDepartmentLoading,
    hideSlotsLoading,
    handleSlotsChange,
    clearSlotsFilters,
    changeSlotsPage,
    showEmployeesLoading,
    hideEmployeesLoading,
    handleEmployeesChange,
    clearEmployeesFilters,
    changeEmployeesPage,
} = departmentSlice.actions;

export default departmentSlice.reducer;
