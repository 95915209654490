import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Landing, Error, Register, ProtectedRoute, AuthRedirect } from './pages';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Profile, 
    AllEmployees, 
    EmployeeInfo, 
    AllDepartments,
    AllSlots,
    SlotInfo,
    Stats, 
    SharedLayout,
    AllFloors,
    FloorInfo,
    DepartmentInfo,
    Calendar,
    Scanner,
    AllUsers,
} from './pages/dashboard';
import { AppProvider } from './components/context';
import {
    AuthenticatedTemplate,
} from "@azure/msal-react";


function App() {
  
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={
            <ProtectedRoute>
                <AuthenticatedTemplate>
                        <SharedLayout />
                </AuthenticatedTemplate>
            </ProtectedRoute>
        }>
            <Route index element={<AppProvider><AllEmployees/></AppProvider>}/>
            <Route path='employees' element={<AppProvider><AllEmployees/></AppProvider>}/>
            <Route path='departments' element={<AppProvider><AllDepartments/></AppProvider>}/>
            <Route path='slots' element={<AppProvider><AllSlots/></AppProvider>}/>
            <Route path='edit-employee' element={<EmployeeInfo/>}/>
            <Route path='profile' element={<Profile />} />
            <Route path='edit-slot' element={<SlotInfo />} />
            <Route path='floors' element={<AppProvider><AllFloors /></AppProvider>} />
            <Route path='edit-floor' element={<FloorInfo />} />
            <Route path='edit-Department' element={<DepartmentInfo />} />
            <Route path='calendar' element={<Calendar />} />
            <Route path='users' element={<AppProvider><AllUsers /></AppProvider>} />


        </Route>


        <Route path='landing' element={<Landing />}></Route>
        <Route path='Scanner' element={<Scanner />}></Route>

        <Route path='authRedirect' element={<AuthRedirect />}></Route>

        <Route path='*' element={<Error/>}></Route>

      </Routes>
      <ToastContainer position='top-center'/>
    </BrowserRouter>
  );

};



export default App;
