import axios from 'axios';
import { clearStore } from '../features/user/userSlice';
import { getUserFromLocalStorage } from './localStorage';
import { PublicClientApplication } from "@azure/msal-browser";
import { loginApiRequest, msalConfig } from "../authConfig"; 
import { useMsal } from "@azure/msal-react";


const customFetch = axios.create({
   // baseURL: 'https://localhost:7255/api/',
    baseURL: 'https://parqueo.automercado.cr/api/',
    //baseURL: 'https://amparking-webapp-d365fo-uat.azurewebsites.net/api/',
});


const msalInstance = new PublicClientApplication(msalConfig);


customFetch.interceptors.request.use(async (config) => {

    try 
    {
        const account = msalInstance.getAllAccounts()[0];
        const response = await msalInstance.acquireTokenSilent({
            ...loginApiRequest,
            account: account,
        });
        const accessToken = response.accessToken;
        config.headers.Authorization = `Bearer ${accessToken}`;
    } catch (err) {
        console.log(err);
    }
    return config;
});


export const checkForUnauthorizedResponse = (error, thunkAPI) => {
    console.log(error.response.status);
    if (error.response.status === 401) {
      
    thunkAPI.dispatch(clearStore());
    return thunkAPI.rejectWithValue('Unauthorized! Logging Out...');
  }
  return thunkAPI.rejectWithValue(error.response.data.msg);
};


export default customFetch;
