import { HiChevronDoubleLeft, HiChevronDoubleRight } from 'react-icons/hi';
import Wrapper from '../../../assets/wrappers/PageBtnContainer';
import { useSelector, useDispatch } from 'react-redux';
import { changeEmployeesPage } from '../../../features/department/departmentSlice';

const DepartmentEmployeesPageBtnContainer = () => {
    const { numOfEmployeePages, employeesPage } = useSelector((store) => store.department);
  const dispatch = useDispatch();

  const pages = Array.from({ length: numOfEmployeePages }, (_, index) => {
    return index + 1;
  });

  const nextPage = () => {
      let newPage = employeesPage + 1;
    if (newPage > numOfEmployeePages) {
      newPage = 1;
    }
      dispatch(changeEmployeesPage(newPage));
  };
  const prevPage = () => {
      let newPage = employeesPage - 1;
    if (newPage < 1) {
      newPage = numOfEmployeePages;
    }
      dispatch(changeEmployeesPage(newPage));
  };

  return (
    <Wrapper>
      <button type='button' className='prev-btn' onClick={prevPage}>
        <HiChevronDoubleLeft />
        prev
      </button>
      <div className='btn-container'>
        {pages.map((pageNumber) => {
          return (
            <button
              type='button'
              key={pageNumber}
                  className={pageNumber === employeesPage ? 'pageBtn active' : 'pageBtn'}
                  onClick={() => dispatch(changeEmployeesPage(pageNumber))}
            >
              {pageNumber}
            </button>
          );
        })}
      </div>
      <button type='button' className='next-btn' onClick={nextPage}>
        next
        <HiChevronDoubleRight />
      </button>
    </Wrapper>
  );
};
export default DepartmentEmployeesPageBtnContainer;
