import customFetch, { checkForUnauthorizedResponse } from '../../utils/axios';

export const getAllWeekDaysThunk = async (_, thunkAPI) => {
    
    
    let url = `/WeekDay`;
    try {
        const resp = await customFetch.get(url);
        return resp.data;
    } catch (error) {
        return checkForUnauthorizedResponse(error, thunkAPI);
    }
};
