import Wrapper from '../assets/wrappers/Car';


const Car = () => {
    return (
        <Wrapper>
            <div className="base">
                <div className="whole">
                    <div className="bug">
                        <div className="bug__whole__shadow"></div>
                        <div className="bug__tire__shadow__ground"></div>
                        <div className="bug__tire__shadow__ground"></div>
                        <div className="bug__tire__shadow"></div>
                        <div className="bug__tire__shadow"></div>
                        <div className="bug__ex"></div>
                        <div className="bug__handle__back"></div>
                        <div className="bug__body__w"></div>
                        <div className="bug__body__f">
                            <div className="bug__body__hood"></div>
                            <div className="bug__body__hood2"></div>
                        </div>
                        <div className="bug__body__b__back">
                            <div className="engineroom">
                                <div className="engine__lines">
                                    <div className="engine__line"></div>
                                    <div className="engine__line"></div>
                                    <div className="engine__line"></div>
                                    <div className="engine__line"></div>
                                    <div className="engine__line"></div>
                                    <div className="engine__line"></div>
                                </div>
                            </div>
                            <div className="window__back__w">
                                <div className="window__back__w__inner"></div>
                            </div>
                        </div>
                        <div className="bug__body__b__top">
                            <div className="window__f">
                                <div className="window__f__inner"></div>
                                <div className="window__f__h__inner"></div>
                                <div className="window__f__stick"></div>
                                <div className="window__f__stick"></div>
                                <div className="window__f__headrest"></div>
                                <div className="window__f__headrest__stick"></div>
                                <div className="window__f__headrest__btm"></div>
                                <div className="window__f__handle"></div>
                            </div>
                            <div className="window__f__h"></div>
                            <div className="window__b">
                                <div className="window__b__inner"></div>
                                <div className="window__b__seat"></div>
                            </div>
                        </div>
                        <div className="bug__longline"></div>
                        <div className="bug__door__line__back"></div>
                        <div className="bug__door">
                            <div className="bug__door__line__right"></div>
                            <div className="bug__door__line__left__btm"></div>
                            <div className="bug__door__line__left__top"></div>
                            <div className="bug__door__box"></div>
                            <div className="bug__door__box"></div>
                        </div>
                        <div className="bug__step__shadow"></div>
                        <div className="bug__headlight"></div>
                        <div className="bug__fender__f">
                            <div className="bug__fender__shadow"></div>
                        </div>
                        <div className="bug__fender__b">
                            <div className="bug__fender__shadow"></div>
                            <div className="bug__fender__shadow__box">
                                <div className="bug__fender__shadow__cir"></div>
                            </div>
                        </div>
                        <div className="bug__taillight"></div>
                        <div className="bug__fender__bb">
                            <div className="bug__fender__shadow"></div>
                            <div className="bug__fender__shadow__box">
                                <div className="bug__fender__shadow__cir"></div>
                            </div>
                        </div>
                        <div className="bug__fender__fb">
                            <div className="bug__fender__shadow"></div>
                            <div className="bug__fender__shadow__box">
                                <div className="bug__fender__shadow__cir"></div>
                            </div>
                        </div>
                        <div className="bug__handle">
                            <div className="bug__handle__shadow"></div>
                            <div className="bug__handle__handle"></div>
                        </div>
                        <div className="bug__tire">
                            <div className="bug__tire__white">
                                <div className="bug__tire__blue">
                                    <div className="bug__tire__blue__dots"></div>
                                    <div className="bug__tire__wheel">
                                        <div className="bug__tire__wheel__logo"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bug__tire">
                            <div className="bug__tire__white">
                                <div className="bug__tire__blue">
                                    <div className="bug__tire__blue__dots"></div>
                                    <div className="bug__tire__wheel">
                                        <div className="bug__tire__wheel__logo"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bug__step"> </div>
                        <div className="bug__bumper__back"></div>
                        <div className="bug__bumper__front"></div>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
};

export default Car;