import customFetch, { checkForUnauthorizedResponse } from '../../utils/axios';

export const getAllSlotsThunk = async (_, thunkAPI) => {
    
  const { page, searchFloor } =
    thunkAPI.getState().allSlots;
    
    let url = `/Slot`;
    if (searchFloor) {
     url = url + `?floor=${searchFloor}`;
    }
    try {
        const resp = await customFetch.get(url);
        return resp.data;
    } catch (error) {
        return checkForUnauthorizedResponse(error, thunkAPI);
    }
};

/**
 * 
 * @param {any} _
 * @param {any} thunkAPI
 */

export const getSlotsAllocationsThunk = async (_, thunkAPI) => {

    const { page, searchFloor } = thunkAPI.getState().allSlots;


    let url = `/Slot/Allocations`;
    if (searchFloor) {
        url = url + `?floor=${searchFloor}`;
    }
    try {
        const resp = await customFetch.get(url);
        return resp.data;
    } catch (error) {
        return checkForUnauthorizedResponse(error, thunkAPI);
    }
};

//
export const getSlotsByDepartmentThunk = async (_, thunkAPI) => {

    const { searchFloor, searchDepartment } =
        thunkAPI.getState().allSlots;

    let url = `/Slot/${searchDepartment}`;
    if (searchFloor) {
        url = url + `?floor=${searchFloor}`;
    }
    try {
        const resp = await customFetch.get(url);
        return resp.data;
    } catch (error) {
        return checkForUnauthorizedResponse(error, thunkAPI);
    }
};

/**
 * 
 * @param {any} _
 * @param {any} thunkAPI
 */

export const getSlotsAllocationsByDepartmentThunk = async (_, thunkAPI) => {

    const { searchFloor, searchDepartment } = thunkAPI.getState().allSlots;


    let url = `/Slot/Allocations/${searchDepartment}`;
    if (searchFloor) {
        url = url + `?floor=${searchFloor}`;
    }
    try {
        const resp = await customFetch.get(url);
        return resp.data;
    } catch (error) {
        return checkForUnauthorizedResponse(error, thunkAPI);
    }
};

/**
 * *
 * @param {any} _
 * @param {any} thunkAPI
 */
export const showStatsThunk = async (_, thunkAPI) => {
  try {
    const resp = await customFetch.get('/slots/stats');

    return resp.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};
