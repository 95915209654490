import { FaLocationArrow, FaAt, FaCalendarAlt, FaPhone, FaIdCard } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Wrapper from '../../assets/wrappers/User';
import { useDispatch, useSelector } from 'react-redux';
import UserInfo from './UserInfo';
import moment from 'moment';
import { deleteUser, setEditUser } from '../../features/user/userSlice';
import swal from 'sweetalert';

const User = ({
    rec_Id,
    person_Rec_Id,
    firstName,
    lastName,
    person_Id,
    email,
    role_Id,
    createdDateTime,
    createdBy,
    updatedDateTime,
    updatedBy
}) => {
  const dispatch = useDispatch();

    const date = moment(createdDateTime).format('MMM Do, YYYY');

    const onDeleteUser = () => {
        swal({
            title: "\u00BF Seguro deseas eliminar como usuario a " + firstName + ' ' + lastName  + "?",
            text: "Una vez eliminado, no ser\u00E1 posible recuperar la informaci\u00F3n",
            icon: "warning",
            buttons: {
                cancel: "Cancelar",
                confirm: "Eliminar",
            },
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    dispatch(deleteUser(person_Rec_Id));
                }
            });

    }

  return (
    <Wrapper>
      <header>
        <div className='main-icon'>{rec_Id}</div>
        <div className='info'>
         <h5>{firstName + " " + lastName}</h5>
          <p>{role_Id}</p>
        </div>
      </header>
      <div className='content'>
        <div className='content-center'>
            <UserInfo icon={<FaAt />} text={email} />
        </div>
        <footer>
          <div className='actions'>
            <button
              type='button'
              className='btn delete-btn'
              onClick={onDeleteUser}
            >
              Eliminar
            </button>
          </div>
        </footer>
      </div>
    </Wrapper>
  );
};
export default User;
