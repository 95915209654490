import { showLoading, hideLoading, getAllFloors } from '../allFloors/allFloorsSlice';
import customFetch, { checkForUnauthorizedResponse } from '../../utils/axios';
import { clearValues } from './floorSlice';

/**
 * 
 * @param {any} floor
 * @param {any} thunkAPI
 */
export const createFloorThunk = async (floor, thunkAPI) => {
    try {
        const resp = await customFetch.post('/Floor/Create', floor);
        thunkAPI.dispatch(getAllFloors());
        return resp.data;
    } catch (error) {
         checkForUnauthorizedResponse(error, thunkAPI);
    }
};

/**
 * 
 * @param {any} floorRecId
 * @param {any} thunkAPI
 */
export const deleteFloorThunk = async (floorRecId, thunkAPI) => {
  thunkAPI.dispatch(showLoading());
  try {
    const resp = await customFetch.delete(`/Floor/${floorRecId}`);
    thunkAPI.dispatch(getAllFloors());
    return resp.data.msg;
  } catch (error) {
    thunkAPI.dispatch(hideLoading());
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};

/**
 * *
 * @param {any} param0
 * @param {any} thunkAPI
 */
export const editFloorThunk = async ({ floorRecId, floor }, thunkAPI) => {
    try {
        const resp = await customFetch.patch(`/Floor/${floorRecId}`, floor);
    //thunkAPI.dispatch(clearValues());
    return resp.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};

/**
 * 
 * @param {any} floorRecId
 * @param {any} thunkAPI
 */
export const getFloorSlotsThunk = async (floorRecId, thunkAPI) => {

  const { slotPage, slotSearch } =
    thunkAPI.getState().floor;

    let url = `/floors/slots/${floorRecId}?page=${slotPage}`;
    if (slotSearch) {
      url = url + `&search=${slotSearch}`;
    }
    try {
      const resp = await customFetch.get(url);
      return resp.data;
    } catch (error) {
      return checkForUnauthorizedResponse(error, thunkAPI);
    }
};