import { useEffect, useState } from 'react';
import Employee from '../Employee';
import Wrapper from '../../../assets/wrappers/EmployeesContainer';
import { useSelector, useDispatch } from 'react-redux';
import Loading from '../../Loading';
import { getAllEmployees, getEmployeesRelatedWithUser } from '../../../features/allEmployees/allEmployeesSlice';
import AllEmployeesPageBtnContainer from './AllEmployeesPageBtnContainer';
import Modal from '../../Modal';
import { useGlobalContext } from '../../context';
import { EmployeeInfoContainer } from '../../';
import { clearValues, deleteEmployee } from '../../../features/employee/employeeSlice';
import swal from 'sweetalert';

const EmployeesContainer = () => {
  const {
    employees,
    isLoading,
    page,
    totalEmployees,
    numOfPages,
    search,
  } = useSelector((store) => store.allEmployees);

    const { user } = useSelector(store => store.user);

    const dispatch = useDispatch();
    const { openSidebar, openModal } = useGlobalContext();

    const openModalAux = () => {
        dispatch(clearValues());
        openModal();
    };


    useEffect(() => {
        if (user.role_Id === 'Secretary')
        {
            dispatch(getEmployeesRelatedWithUser(user.rec_Id));
        }
        else {
            dispatch(getAllEmployees());
        }
  }, [page, search]);


    const onDeleteEmployee = (employeeRecId, employeeName) => {
        swal({
            title: "\u00BF Seguro deseas eliminar a " + employeeName + "?",
            text: "Una vez eliminado, no ser\u00E1 posible recuperar la informaci\u00F3n",
            icon: "warning",
            buttons: {
                cancel: "Cancelar",
                confirm: "Eliminar",
            },
            dangerMode: true,
        })
        .then((willDelete) => {
            if (willDelete) {
                dispatch(deleteEmployee(employeeRecId));
            }
        });

    }

  if (isLoading) {
    return <Loading />;
  }

  if (employees.length === 0) {
    return (
        <Wrapper>
            {
                user.role_Id == "Admin" ? 
                <button onClick={openModalAux} className='btn' >
                    Agregar colaborador
                    </button>
                 : ""
            }
        <Modal>
          <EmployeeInfoContainer/>
        </Modal>
         <h5>

        </h5>
        <h2>No hay colaboradores para mostrar...</h2>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <h5>
        {totalEmployees} colaborador{employees.length > 1 && 'es'} encontrado{employees.length > 1 && 's'}
      </h5>
      
          <div className='employees'>

              {user.role_Id == "Admin" ?
                  <button onClick={openModalAux} className='btn' hidden={user.role_Id != "Admin"}>
                      Agregar colaborador
                  </button>
                  :
                  ""
              }
        <Modal>
          <EmployeeInfoContainer/>
        </Modal>
              {employees.map((employee) => {
          
                return <Employee key={employee.rec_Id} {...employee} onDelete={user.role_Id == "Admin" ? onDeleteEmployee : null} />;
   

        })}
      </div>
      {numOfPages > 1 && <AllEmployeesPageBtnContainer />}
    </Wrapper>
  );
};
export default EmployeesContainer;
