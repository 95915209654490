import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Loading from '../../Loading';
import Wrapper from '../../../assets/wrappers/Calendar';
import { getEmployeeSlots } from '../../../features/employee/employeeSlice';
import { getAllWeekDays } from '../../../features/allWeekDays/allWeekDaysSlice';


const CalendarContainer = () => {
    const {
        allocations,
        isLoading,
        floorSearch,
        editRecId,
    } = useSelector((store) => store.employee);

    const {
        weekDays
    } = useSelector((store) => store.allWeekDays);

    const { user } = useSelector(store => store.user)

    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(getAllWeekDays());
    }, []);

    useEffect(() => {
        dispatch(getEmployeeSlots(editRecId));
    }, [floorSearch]);

    if (isLoading || weekDays.length == 0) {
        return <Loading />;
    }


    return (
        <Wrapper>
           

            <div >
                <table>
                    <thead>
                        <tr>
                            {weekDays.map(day => (
                                <th key={day.rec_Id} className="table-header">{day.name}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        <tr key="slotsAssigned">
                            {weekDays.map(day => (
                                <td key={"slots" + day.rec_Id} className="table-cell">
                                    <ul>
                                    {allocations[day.rec_Id] ? allocations[day.rec_Id].map((slot, empIndex) => (
                                        <li className="employee" key={"itemSlot" + empIndex} >
                                            {slot.slot_Id}
                                        </li>
                                    )) : ''}
                                    </ul>
                                </td>
                            ))}
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>

                        </tr>
                    </tfoot>
                </table>
            </div>

        </Wrapper>
    );
};

export default CalendarContainer;
