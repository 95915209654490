import { useEffect, useState } from 'react';
import Employee from '../../employee/Employee';
import Wrapper from '../../../assets/wrappers/EmployeesContainer';
import { useSelector, useDispatch } from 'react-redux';
import Loading from '../../Loading';
import { getDepartmentEmployees, addEmployeesToDepartment, deleteDepartmentEmployee} from '../../../features/department/departmentSlice';
import DepartmentEmployeesPageBtnContainer from './DepartmentEmployeesPageBtnContainer';
import SelectAsyncPaginateEmployees from "../../employee/allEmployees/SelectAsyncPaginateEmployees";
import Modal from '../../Modal';
import { useGlobalContext } from '../../context';
import { getNotIncludedDepartmentEmployees, getAllEmployees } from '../../../features/allEmployees/allEmployeesSlice';
import { toast } from "react-toastify";
import swal from 'sweetalert';

const DepartmentEmployeesContainer = () => {
    const {
        employees,
        areEmployeesLoading,
        employeesPage,
        totalEmployees,
        numOfEmployeePages,
        employeeSearch,
        editDepartmentRecId,
    } = useSelector((store) => store.department);
    const dispatch = useDispatch();
    const { isModalOpen, openModal, closeModal } = useGlobalContext();
    const { user } = useSelector((store) => store.user);

    const [selectedEmployees, setSelectedEmployees] = useState([]);

    useEffect(() => {
        dispatch(getDepartmentEmployees(editDepartmentRecId));
    }, [employeesPage, employeeSearch]);


    const openModalAux = () => {
        //setSelectedEmployees([...selectedEmployees, ...employees]);
        openModal();

    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (selectedEmployees.length === 0) {
            toast.error("Por favor llenar todos los datos.");
            return;
        }
        closeModal();

        dispatch(addEmployeesToDepartment({
            departmentRecId: editDepartmentRecId,
            employees: selectedEmployees
        }));
    }

    const onDeleteEmployee = (employeeRecId, employeeName) => {
        swal({
            title: "\u00BF Seguro deseas excluir a " + employeeName + " de este departamento?",
            text: "Una vez eliminado, no ser\u00E1 posible recuperar la informaci\u00F3n",
            icon: "warning",
            buttons: {
                cancel: "Cancelar",
                confirm: "Eliminar",
            },
            dangerMode: true,
        })
        .then((willDelete) => {
            if (willDelete) {
                dispatch(deleteDepartmentEmployee({ departmentRecId: editDepartmentRecId, employeeRecId }));
            }
        });

    }

    if (areEmployeesLoading) {
        return <Loading />;
    }

    if (employees.length === 0) {
        return (
            <Wrapper>
                {user.role_Id == "Security" ? "" :
                    <button onClick={openModalAux} className='btn'>
                        Agregar colaboradores
                    </button>
                }
                <h4></h4>
                <Modal>
                    <form className="form">
                        <h3>{'Agregar colaboradores'}</h3>

                        <div className="form-center">
                            <SelectAsyncPaginateEmployees
                                value={selectedEmployees}
                                onChange={setSelectedEmployees}
                                methodAPI={getNotIncludedDepartmentEmployees}
                                sourceRecId={editDepartmentRecId}
                            />
                            <button
                                className='btn btn-block submit-btn'
                                onClick={handleSubmit}
                                disabled={selectedEmployees.length === 0}
                            >
                                Agregar
                            </button>
                        </div>
                    </form>
                </Modal>
                <h2>No hay colaboradores para mostrar...</h2>
            </Wrapper>
        );
    }



    return (
        <Wrapper>
            <h5>
                {totalEmployees} colaborador{employees.length > 1 && 'es'} encontrado{employees.length > 1 && 's'}
            </h5>
            <div className='employees'>
                {user.role_Id == "Security" ? "" :
                    <button onClick={openModalAux} className='btn'>
                        Agregar colaboradores
                    </button>
                }
                <Modal>
                    <form className="form">
                        <h3>{'Agregar colaboradores'}</h3>

                        <div className="form-center">
                            <SelectAsyncPaginateEmployees
                                value={selectedEmployees}
                                onChange={setSelectedEmployees}
                                methodAPI={getNotIncludedDepartmentEmployees}
                                sourceRecId={editDepartmentRecId}
                                isMulti={ true }
                            />
                            <button
                                className='btn btn-block submit-btn'
                                onClick={handleSubmit}
                                disabled={selectedEmployees.length === 0}
                            >
                                Agregar
                            </button>
                        </div>
                    </form>
                </Modal>
                {employees.map((employee) => {
                    return <Employee key={employee.rec_Id} {...employee} onDelete={user.role_Id == "Security" ? null : onDeleteEmployee} />;
                })}
            </div>
            {numOfEmployeePages > 1 && <DepartmentEmployeesPageBtnContainer />}
        </Wrapper>
    );
};
export default DepartmentEmployeesContainer;
