import styled from 'styled-components';

const Wrapper = styled.div`
    .table {
        width: 100%;
        border-collapse: collapse;
        background-color: white;
        border-radius: 10px;   
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        max-width: 100%;
    }

    .table-header {
        background-color: #00a651;
        color: white;
        border: 1px solid #ddd;
        padding: 8px;
        text-align: center;

    }
    .table-cell {
        padding: 8px;
        text-align: center;
        background-color: white;
        border-radius: 10px;
    }
    .table-cell-slot {
        padding: 8px;
        text-align: center;
        background-color: #00a651;
        color: white;
        border: 1px solid #ddd;
        border-radius: 10px;
    }
    .employee {
        display: inline-block;
        background-color: #dfe6e0;
        border-radius: 5%;
        text-align: center;
        line-height: 40px;
        margin-right: 10px;
        color: black;
    }

    .form-center {
        display: grid;
        grid-template-columns: 1fr;
        column-gap: 2rem;
        row-gap: 0.5rem;
     }
      @media (min-width: 768px) {
        .form-center {
          grid-template-columns: 1fr 1fr;
        }
      }
      @media (min-width: 992px) {
        .form-center {
          grid-template-columns: 1fr 1fr 1fr;
        }
     }

`;

export default Wrapper;