import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { getAllRolesThunk, showStatsThunk, getRolesAllocationsThunk } from './allRolesThunk';


const initialState = {
    areRolesLoading: true,
    roles: [],
};

export const getAllRoles = createAsyncThunk('allRoles/getRoles', getAllRolesThunk);

const allRolesSlice = createSlice({
  name: 'allRoles',
  initialState,
  reducers: {
    showLoading: (state) => {
      state.areRolesLoading = true;
    },
    hideLoading: (state) => {
      state.areRolesLoading = false;
    },
    handleChange: (state, { payload: { name, value } }) => {
      state.page = 1;
      state[name] = value;
    },

    clearAllRolesState: (state) => initialState,
    
  },
  extraReducers: {
    [getAllRoles.pending]: (state) => {
      state.areRolesLoading = true;
    },
    [getAllRoles.fulfilled]: (state, { payload }) => {
      state.areRolesLoading = false;
        state.roles = payload.roles;
    //  state.numOfPages = payload.numOfPages;
    //  state.totalRoles = payload.totalRoles;
    },
    [getAllRoles.rejected]: (state, { payload }) => {
      state.areRolesLoading = false;
      toast.error(payload);
     },
  },
});


export const {
  showLoading,
  hideLoading,
  handleChange,
  clearAllRolesState,
} = allRolesSlice.actions;

export default allRolesSlice.reducer;
