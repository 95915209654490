import styled from 'styled-components'
import asphalt from '../images/asphalt.png';

const Wrapper = styled.section`
  
  h2 {
    text-transform: none;
  }
  & > h5 {
    font-weight: 700;
  }
    .slots {
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 2rem;
    }
    .slotContainer {
      position: relative;
      border: 1px solid black;
      height: 2000px;
      width: 2500px;
      background-color: #282829;
    }

    .mapContainer {
        height: 80vh;
        width: 90vw;
        background-color: #282829;
        overflow: scroll; 
    }


  @media (min-width: 992px) {
    .slots {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
    }
  }
`
export default Wrapper
