import { useEffect  } from 'react';
import { FormRow, FormRowSelect } from '../../';
import Wrapper from '../../../assets/wrappers/SearchContainer';
import { useSelector, useDispatch } from 'react-redux';
import { handleChange } from '../../../features/employee/employeeSlice';
import { getAllFloors } from '../../../features/allFloors/allFloorsSlice';

const SearchContainerEmployeeCalendar = ({ title }) => {

    var { floorSearch } =
        useSelector((store) => store.employee);

    const { user, isLoading } = useSelector(store => store.user);

    const dispatch = useDispatch();

    const { floors, areFloorsLoading } = useSelector((store) => store.allFloors);
    const floor_list = floors.map((floor) => {
        return { label: floor.floor_Id, value: floor.rec_Id };
    });



    useEffect(() => {
        dispatch(getAllFloors()).then((response) => {
            dispatch(handleChange({ name: 'floorSearch', value: response.payload.floors.length > 0 ? response.payload.floors[0].rec_Id : 0 }));
        });;
    }, []);


    const handleSearch = (e) => {

        if (areFloorsLoading) return;
        dispatch(handleChange({ name: e.target.name, value: e.target.value }));
    };



    return (
    <Wrapper>
        <form className='form'>
        <h3>{title}</h3>
        <div className='form-center'>
            {/* search by floor*/}
            <FormRowSelect
                labelText='Piso'
                name='floorSearch'
                value={floorSearch}
                handleChange={handleSearch}
                list={floor_list}
            />
        </div>
        </form>
    </Wrapper>
    );
};
export default SearchContainerEmployeeCalendar;
