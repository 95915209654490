import Wrapper from '../../assets/wrappers/FloorInfo';

const FloorInfo = ({ icon, text }) => {
  return (
    <Wrapper>
      <span className='icon'>{icon} </span>
      <span className='text'>{text} </span>
    </Wrapper>
  );
};
export default FloorInfo;
