import { FormRow, FormRowSelect } from '../..';
import Wrapper from '../../../assets/wrappers/SearchContainer';
import { useSelector, useDispatch } from 'react-redux';
import { handleDepartmentsChange, clearDepartmentsFilters } from '../../../features/employee/employeeSlice';

const SearchContainer = () => {
    const { areDepartmentsLoading, departmentSearch, firstName, lastName } =
        useSelector((store) => store.employee);

    //const { jobTypeOptions, statusOptions } = useSelector((store) => store.employee);

    const dispatch = useDispatch();

    const handleSearch = (e) => {
        if (areDepartmentsLoading) return;
        dispatch(handleDepartmentsChange({ name: e.target.name, value: e.target.value }));
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(clearDepartmentsFilters());
    };

    return (
        <Wrapper>
            <form className='form'>
                <h3>Departamentos asociados a <b>{firstName + " " + lastName}</b></h3>
                <div className='form-center'>
                    {/* search position */}
                    <FormRow
                        type='text'
                        name='departmentSearch'
                        value={departmentSearch}
                        handleChange={handleSearch}
                        labelText="Buscar"
                        placeholder="Nombre..."
                    />

                </div>
            </form>
        </Wrapper>
    );
};
export default SearchContainer;
