import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Wrapper from "../../../assets/wrappers/DashboardFormPage";
import { FormRow, FormRowInfo } from "../..";
import { clearValues, handleChange, editSlot, deleteSlot} from "../../../features/slot/slotSlice";
import userSlice from "../../../features/user/userSlice";
import { useNavigate } from "react-router-dom"
import swal from 'sweetalert';

const EditSlot = () => {
  const {
    isLoading,
    slot_Id,
    available,
    coordX,
      coordY,
    used_By,
    floor,
    createdDateTime,
    updatedDateTime,
    createdBy,
    updatedBy,
    isEditing,
    editSlotRecId,
  } = useSelector((store) => store.slot);
    const { user } = useSelector((store) => store.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isAdmin, setIsAdmin] = useState(false);


  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (!slot_Id)
    {
      toast.error("Por favor llenar todos los datos.");
      return;
    }

      if (isEditing) {
          dispatch(editSlot({ slotRecId: editSlotRecId, slot: {slot_Id, available, coordX, coordY} }));
      }
 
  }

  const handleSlotInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    dispatch(handleChange({ name, value }));
  };

    const onDeleteSlot = () => {

        swal({
            title: "\u00BF Seguro deseas eliminar el espacio " + slot_Id + "?",
            text: "Una vez eliminado, no ser\u00E1 posible recuperar la informaci\u00F3n",
            icon: "warning",
            buttons: {
                cancel: "Cancelar",
                confirm: "Eliminar",
            },
            dangerMode: true,
        })
        .then((willDelete) => {
            if (willDelete) {
                dispatch(deleteSlot(editSlotRecId))
                    .then((data) => {
                        if (data.meta.requestStatus === "fulfilled")
                            navigate('/slots');
                    });
            }
        });
    }

    useEffect(() => {
        setIsAdmin(user.role_Id == "Admin");
    }, []);

  return (
    <Wrapper>
      <form className="form">
        <h3>{isEditing ? 'Información del espacio' : 'Agregar espacio'}</h3>

        <div className="form-center">
          {/* first_name */}
        <FormRow
                      type='text'
                      labelText='Identificación del espacio'
                      name='slot_Id'
                      value={slot_Id}
                      handleChange={handleSlotInput}
                      disabled={!isAdmin}
                  />

        <FormRowInfo
            type='text'
            labelText='Utilizado por'
            name='used_By'
            value={used_By}
            visible={isEditing && used_By != ""}
        />

          

            {/* btn container */}
            {isAdmin ?
                <div className='btn-containner'>
                    {/*<button
                    type='button'
                    className='btn btn-block clear-btn'
                    onClick={() => dispatch(clearValues())}
                >
                    clear
                </button>*/}
                    <button
                        type='submit'
                        className='btn btn-block submit-btn'
                        onClick={handleSubmit}
                        disabled={isLoading}
                    >
                        Guardar
                    </button>
                    <button
                        type='button'
                        className='btn btn-block delete-btn'
                        onClick={onDeleteSlot}
                        disabled={isLoading}
                    >
                        Eliminar espacio
                    </button>

                </div>
                :
                ""
            }
        </div>
      </form>
    </Wrapper>
  );
};

export default EditSlot;