import { Link } from 'react-router-dom';
import img from '../assets/images/access_denied.svg';
import Wrapper from '../assets/wrappers/ErrorPage';

const AccessDenied = () => {
    return (
      <Wrapper className='full-page'>
        <div>
          <img src={img} alt='access denied' />
          <h3>¡Oh no! No tienes acceso a esta página</h3>
          <p>No puedes acceder al contenido de esta página</p>
          <Link to='/'>Volver</Link>
        </div>
      </Wrapper>
    );
  };
  
 export default AccessDenied;