import { useEffect, useRef, useState  } from 'react';
import Slot from '../Slot';
import Wrapper from '../../../assets/wrappers/SlotContainer';
import { useSelector, useDispatch } from 'react-redux';
import Loading from '../../Loading';
import { getAllSlots, getSlotsByDepartment } from '../../../features/allSlots/allSlotsSlice';
import AllSlotsPageBtnContainer from './AllSlotsPageBtnContainer';
import Modal from '../../Modal';
import { useGlobalContext } from '../../context';
import { SlotInfoContainer } from '../..';
import { clearValues, createSlot } from '../../../features/slot/slotSlice';
import SearchContainerSlotDepartments from '../slotInfo/SearchContainerSlotDepartments';
import SlotDepartmentsContainer from '../slotInfo/SlotDepartmentsContainer';


const SlotsContainer = () => {
    const {
        slots,
        isLoading,
        totalSlots,
        searchFloor,
        searchDepartment,
    } = useSelector((store) => store.allSlots);
    const dispatch = useDispatch();
    const { user } = useSelector(store => store.user)

    const [editing, setEditing] = useState(false)
    const [editText, setEditText] = useState("Editar")
    const [slotList, setSlotList] = useState([]);


    const containerRef = useRef(null);
    const editButtonRef = useRef(null);
    let isEditingRef = useRef(false);
    // const boxRef = useRef(null);

    const isClicked = useRef(false);

    const coords = useRef({
        startX: 0,
        startY: 0,
        lastX: 0,
        lastY: 0
    });



    const handleEditing = (e) => {
        e.preventDefault();
    
        setEditing(!editing);
        
        if (!editing)    
            setEditText("Guardar");
        else
            setEditText("Editar posiciones");

        isEditingRef.current = !editing;

        if (editing) {
          //  dispatch(getAllSlots());
        }

    }


    useEffect(() => {
        if (user.role_Id == "Secretary") {
            dispatch(getSlotsByDepartment());
        }
        else {
            dispatch(getAllSlots());
        }
         setSlotList([]);
      }, [searchFloor, searchDepartment]);

      if (isLoading) {
        return <Loading />;
      }



    const addSlotButton = () => {
        setSlotList([...slotList, {rec_Id: 0, slot_Id: "<Sin ID>", available: 1, floor: searchFloor, coordX: containerRef.current.clientWidth / 2, coordY: containerRef.current.clientHeight / 2}]);
    };       

    return (
    <Wrapper>

            {user.role_Id == "Admin" ? 
                
                <div>
                    <button onClick={addSlotButton} className='btn' disabled={!editing}>
                      Agregar espacio
                    </button>

                    <button ref={editButtonRef} onClick={handleEditing}  className='btn'>
                      {editText}
                        </button>
                </div> : ""
            }

        <div className="mapContainer">
            <div ref={containerRef} className="slotContainer">
                    {slots.map((slot) => {
                    return <Slot key={slot.rec_Id} {...slot} containerRef={containerRef} isEditingRef={isEditingRef}/>
                })}
                {slotList.map((slot, index) => {
                    return <Slot key={"n" + index} {...slot} floor={ searchFloor } containerRef={containerRef} isEditingRef={isEditingRef} isNew={true}/>
                })}

                </div>
        </div>
    </Wrapper>
    );

};
export default SlotsContainer;
