import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { getUserFromLocalStorage } from '../../utils/localStorage';
import { createFloorThunk, deleteFloorThunk, editFloorThunk,  getFloorSlotsThunk } from './floorThunk';

const initialSlotsFiltersState = {
  slotSearch: '',
 /* searchStatus: 'all',
  searchType: 'all',
  sort: 'latest',
  sortOptions: ['latest', 'oldest', 'a-z', 'z-a'],*/
};

const initialState = {
    isLoading: false,
    floor_Id: '',
    createdDateTime: '',
    updatedDateTime: '',
    createdBy: '',
    updatedBy: '',
    isEditing: false,
    editFloorRecId: '',
    areSlotsLoading: true,
    slots: [],
    totalSlots: 0,
    numOfSlotPages: 1,
    slotsPage: 1,
  ...initialSlotsFiltersState,
};

export const createFloor = createAsyncThunk('floor/createFloor', createFloorThunk);
export const deleteFloor = createAsyncThunk('floor/deleteFloor', deleteFloorThunk);
export const editFloor = createAsyncThunk('floor/editFloor', editFloorThunk);
export const getFloorSlots = createAsyncThunk('floor/getFloorSlots', getFloorSlotsThunk);


const floorSlice = createSlice({
  name: 'floor',
  initialState,
  reducers: {
    handleChange: (state, { payload: { name, value } }) => {
      state[name] = value;
    },
    clearValues: () => {
      return {
        ...initialState,
        //jobLocation: getUserFromLocalStorage()?.location || '',
      };
    },
    setEditFloor: (state, { payload }) => {
      return { ...state, isEditing: true, ...payload };
    },
    showSlotsLoading: (state) => {
      state.areSlotsLoading = true;
    },
    hideSlotsLoading: (state) => {
      state.areSlotsLoading = false;
    },
    handleSlotsChange: (state, { payload: { name, value } }) => {
      state.slotsPage = 1;
      state[name] = value;
    },
    clearSlotsFilters: (state) => {
      return { ...state, ...initialSlotsFiltersState };
    },
    changeSlotsPage: (state, { payload }) => {
      state.slotsPage = payload;
    },
  },
  extraReducers: {
    [createFloor.pending]: (state) => {
      state.isLoading = true;
    },
    [createFloor.fulfilled]: (state) => {
      state.isLoading = false;
      toast.success('Piso creado');
    },
    [createFloor.rejected]: (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload);
    },
    [deleteFloor.fulfilled]: (state, { payload }) => {
      toast.success(payload);
    },
    [deleteFloor.rejected]: (state, { payload }) => {
      toast.error(payload);
    },
    [editFloor.pending]: (state) => {
      state.isLoading = true;
    },
    [editFloor.fulfilled]: (state) => {
      state.isLoading = false;
      toast.success('Piso modificado...');
    },
    [editFloor.rejected]: (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload);
    },
    [getFloorSlots.pending]: (state) => {
      
      state.areSlotsLoading = true;
    },
    [getFloorSlots.fulfilled]: (state, { payload }) => {
      state.areSlotsLoading = false;
      state.slots = payload.slots;
      state.numOfSlotPages = payload.numOfPages;
      state.totalSlots = payload.totalSlots;
    },
    [getFloorSlots.rejected]: (state, { payload }) => {
      state.areSlotsLoading = false;
      toast.error(payload);
    },
  },
});

export const { 
  handleChange, 
  clearValues, 
  setEditFloor,
  showFloorLoading,
  hideSlotsLoading,
  handleSlotsChange,
  clearSlotsFilters,
  changeSlotsPage,
} = floorSlice.actions;

export default floorSlice.reducer;
