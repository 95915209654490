import { showLoading, hideLoading, getAllDepartments } from '../allDepartments/allDepartmentsSlice';
import customFetch, { checkForUnauthorizedResponse } from '../../utils/axios';
import { clearValues, getDepartmentEmployees } from './departmentSlice';

export const createDepartmentThunk = async (department, thunkAPI) => {
  try {
    const resp = await customFetch.post('/Department', department);
    thunkAPI.dispatch(getAllDepartments());
    return resp.data.msg;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};
export const deleteDepartmentThunk = async (departmentRecId, thunkAPI) => {
  thunkAPI.dispatch(showLoading());
  try {
    const resp = await customFetch.delete(`/Department/${departmentRecId}`);
    thunkAPI.dispatch(getAllDepartments());
    return resp.data.msg;
  } catch (error) {
    thunkAPI.dispatch(hideLoading());
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};
export const editDepartmentThunk = async ({ departmentRecId, department }, thunkAPI) => {
  try {
    const resp = await customFetch.patch(`/Department/${departmentRecId}`, department);
    //thunkAPI.dispatch(clearValues());
    return resp.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const getDepartmentSlotsThunk = async (departmentRecId, thunkAPI) => {

  const { slotPage, slotSearch } =
    thunkAPI.getState().department;

    let url = `/departments/slots/${departmentRecId}?page=${slotPage}`;
    if (slotSearch) {
      url = url + `&search=${slotSearch}`;
    }
    try {
      const resp = await customFetch.get(url);
      return resp.data;
    } catch (error) {
      return checkForUnauthorizedResponse(error, thunkAPI);
    }
};



export const getDepartmentEmployeesThunk = async (departmentRecId, thunkAPI) => {

    const { employeesPage, employeeSearch, employeesLimit } =
        thunkAPI.getState().slot;

    if (!employeesLimit)
        employeesLimit = 10;

    let url = `/Department/Employees/${departmentRecId}?page=${employeesPage}&limit=${employeesLimit}`;
    if (employeeSearch) {
        url = url + `&search=${employeeSearch}`;
    }
    else {
        url = url + `&search=""`;
    }

    try {
        const resp = await customFetch.get(url);
        return resp.data;
    } catch (error) {
        return checkForUnauthorizedResponse(error, thunkAPI);
    }
};


export const addEmployeesToDepartmentThunk = async ({ departmentRecId, employees }, thunkAPI) => {
    try {
        const resp = await customFetch.patch(`/Department/Employees/AddEmployees/${departmentRecId}`, employees);
        thunkAPI.dispatch(getDepartmentEmployees(departmentRecId));
        return resp.data;
    } catch (error) {
        return checkForUnauthorizedResponse(error, thunkAPI);
    }
};


export const deleteDepartmentEmployeeThunk = async ({ departmentRecId, employeeRecId }, thunkAPI) => {
    try {
        const resp = await customFetch.delete(`/Department/Employees/DeleteEmployee/${departmentRecId}?employee=${employeeRecId}`);
        thunkAPI.dispatch(getDepartmentEmployees(departmentRecId));
        return resp.data;
    } catch (error) {
        return checkForUnauthorizedResponse(error, thunkAPI);
    }
};