import { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from "prop-types";
import { AsyncPaginate } from "react-select-async-paginate";
import { handleChange, changePage, getAllDepartments, clearAllDepartmentsState, getNotIncludedSlotDepartments } from '../../../features/allDepartments/allDepartmentsSlice';
//import { selectionStore } from "../../../selectionStore";

const SelectAsyncPaginateDepartments = (props) => {
    const {
        departments,
        areDepartmentsLoading,
        page,
        totalDepartments,
        numOfPages,
        search,
    } = useSelector((selectionStore) => selectionStore.allDepartments);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(clearAllDepartmentsState());
    }, []);

    const loadOptions = async (searchQuery, loadedOptions, { pageSelect }) => {
        dispatch(handleChange({ name: 'search', value: searchQuery }));
        await dispatch(changePage(pageSelect));
        await dispatch(props.methodAPI(props.sourceRecId));

        if (pageSelect > numOfPages) {
            dispatch(clearAllDepartmentsState()); 
        }
        return {
            options: departments,
            hasMore: pageSelect <= numOfPages,
            additional: {
                pageSelect: searchQuery ? 2 : pageSelect + 1
            }
        };
    };

    const onChange = (option) => {
        if (typeof props.onChange === "function") {
            props.onChange(option);
        }
    };

    return (
        <AsyncPaginate
            //key={JSON.stringify(1)}
            value={props.value || ""}
            loadOptions={loadOptions}
            isMulti
            getOptionValue={(option) => option.rec_Id}
            getOptionLabel={(option) => (option.rec_Id + " - " + option.name)}
            onChange={onChange}
            isSearchable={true}
            placeholder="Seleccionar departamento"
            additional={{
                pageSelect: 1
            }}
        />
    );
};

SelectAsyncPaginateDepartments.propTypes = {
    value: PropTypes.array,
    onChange: PropTypes.func
};

export default SelectAsyncPaginateDepartments;
