import { IoBarChartSharp } from 'react-icons/io5';
import { MdQueryStats, MdAccountBalance } from 'react-icons/md';
import { ImProfile } from 'react-icons/im';
import { 
    FaDog, 
    FaRegUserCircle, 
    FaRegSun, 
    FaSyringe, 
    FaTh,
    FaVenusMars,
    FaHorse,
    FaRainbow,
    FaCarAlt,
    FaCarSide
} from 'react-icons/fa';
import { RiArrowDownSFill, RiArrowUpSFill } from 'react-icons/ri';
import { BsLadder, BsCalendarWeek } from 'react-icons/bs';

const sideBarData = [
    {
        id: 2,
        title: "Parqueo",
        icon: <FaCarAlt/>,
        children: [
            {
                id: 2.1,
                title: "Espacios",
                path: "slots",
                icon: <FaTh />,
                access: ["Admin", "Secretary", "Security"],
            },
            {
                id: 2.2,
                title: "Pisos",
                path: "floors",
                icon: <BsLadder />,
                access: ["Admin"],
            },
            {
                id: 2.3,
                title: "Calendario",
                path: "calendar",
                icon: <BsCalendarWeek />,
                access: ["Admin", "Secretary", "Security"],
            },
            
        ]
    },
    {
        id: 3,
        title: "Colaboradores",
        icon: <ImProfile/>,
        children: [
            {
                id: 3.1,
                title: "Todos los colaboradores",
                path: "employees",
                icon: <FaTh />,
                access: ["Admin", "Secretary", "Security"],
            },
        ]
    },
    {
        id: 4,
        title: "Departamentos",
        path: "departments",
        icon: <MdAccountBalance/>,
        children: [
            {
                id: 4.1,
                title: "Todos los departamentos",
                path: "departments",
                icon: <FaTh />,
                access: ["Admin", "Secretary"],
            },
        ]
    },
    {
        id: 5,
        title: "Configuración",
        icon: <FaRegSun />,
        access: ["Admin"],
        children: [
            {
                id: 5.1,
                title: "Usuarios",
                path: "users",
                icon: <ImProfile/>,
            },
            
            
        ]
    },
];

export default sideBarData;