import { DepartmentsContainer, SearchContainerDepartments } from '../../components';


const AllDepartments = () => {
  return (
    <>
      <SearchContainerDepartments />
      <DepartmentsContainer />
    </>
  );
};
export default AllDepartments;
