import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
    auth: {
        clientId: "09372b09-5d0f-4c56-b91b-810601fbf0fb", // Client ID 
        authority: 'https://login.microsoftonline.com/dbe28bd2-4e2d-40c7-88c3-cfd8d151c41c/', // Tenant ID of the React.JS App Registration
        /** //UAT
        redirectUri: "https://amparking-webapp-d365fo-uat.azurewebsites.net/landing",
        postLogoutRedirectUri: 'https://amparking-webapp-d365fo-uat.azurewebsites.net/landing',*/


         //PROD
        redirectUri: "https://parqueo.automercado.cr/landing",
        postLogoutRedirectUri: 'https://parqueo.automercado.cr/landing',


        /* //DEV
        redirectUri: "https://localhost:3000/landing",
        postLogoutRedirectUri: 'https://localhost:3000/landing',*/
        clientSecret: '1Kp8Q~8jYVDFyPJmD1jg0Bo1Wj~5jIuYol6GsbIG',
        scopes: ['api://09372b09-5d0f-4c56-b91b-810601fbf0fb/user.read'],
        grant_type: "client_credentials"
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
    },
};

// Can be found in the API Permissions of the ASP.NET Web API
export const loginApiRequest = {
    scopes: [
        "api://09372b09-5d0f-4c56-b91b-810601fbf0fb/user.read",
    ],
};