import { useEffect, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Wrapper from "../../../assets/wrappers/DashboardFormPage";
import { FormRow } from "../..";
import FormRowSelect from "../../FormRowSelect";
import { clearValues, handleChange, editEmployee, createEmployee } from "../../../features/employee/employeeSlice";
import userSlice from "../../../features/user/userSlice";
import { useGlobalContext } from '../../context';


const EditEmployee = () => {
    const {
        isLoading,
        person_Id,
        badge_Id,
        firstName,
        lastName,
        email,
        phone,
        createdDateTime,
        updatedDateTime,
        createdBy,
        updatedBy,
        isEditing,
        editRecId,
    } = useSelector((store) => store.employee);
    const { user } = useSelector((store) => store.user);
    const dispatch = useDispatch();
    const [ isAdmin, setIsAdmin ] = useState(false);
    //const { closeModal } = useGlobalContext();

    const isEmail = (email) =>
        /^[A-Z0-9._%+-]+@automercado.biz$/i.test(email);

    const handleSubmit = (e) => {
        e.preventDefault();
    
        if (!firstName || !lastName)  
        {
            toast.error("Por favor llenar todos los datos de manera correcta.");
            return;
        }

        if (!person_Id) {
            toast.error("Identificación inválida.");
            return;
        }

        if (!isEmail(email)) {
            toast.error("Correo no es válido.");
            return;
        }

        if (!isEditing) {
            dispatch(createEmployee({ person_Id, badge_Id, firstName, lastName, email, phone }));
            //closeModal();
        }
        else {
            dispatch(editEmployee({ employeeRecId: editRecId, employee: { person_Id, badge_Id, firstName, lastName, email, phone }}));
        }
    }

    const handleEmployeeInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        dispatch(handleChange({ name, value }));
    };

    useEffect(() => {
        setIsAdmin(user.role_Id == "Admin");
    }, []);

  return (
    <Wrapper>
      <form className="form">
        <h3>{isEditing ? 'Información del colaborador' : 'Agregar colaborador'}</h3>

        <div className="form-center">
            {/* person_id */}
            <FormRow
                      type='number'
                      labelText='Cédula'
                      name='person_Id'
                      value={person_Id}
                      handleChange={handleEmployeeInput}
                      disabled={!isAdmin || isEditing}
            />

            {/* first_name */}
            <FormRow
                type='text'
                labelText='Nombre'
                name='firstName'
                value={firstName}
                handleChange={handleEmployeeInput}
                disabled={!isAdmin}
                onlyAlpha={true}

            />

            {/* last_name */}
            <FormRow
                type='text'
                labelText='Apellidos'
                name='lastName'
                value={lastName}
                handleChange={handleEmployeeInput}
                disabled={!isAdmin}
                onlyAlpha={true}
            />

            {/* badge_Id */}
            <FormRow
                type='text'
                labelText='Número de badge'
                name='badge_Id'
                value={badge_Id}
                      handleChange={handleEmployeeInput}
                      disabled={!isAdmin}

            />
            {/* email */}
            <FormRow
                type='email'
                labelText='Correo'
                name='email'
                value={email}
                      handleChange={handleEmployeeInput}
                      disabled={!isAdmin}

             />


          {/* btn container */}
          <div className='btn-containner'>
            {/*<button
              type='button'
              className='btn btn-block clear-btn'
              onClick={() => dispatch(clearValues())}
            >
              clear
            </button>*/}
            {isAdmin ?
                <button
                    type='submit'
                    className='btn btn-block submit-btn'
                    onClick={handleSubmit}
                    disabled={isLoading}
                >
                    Guardar
                </button>
                :
                ""
            }
          </div>
        </div>
      </form>
    </Wrapper>
  );
};

export default EditEmployee;