import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Wrapper from "../../../assets/wrappers/DashboardFormPage";
import { FormRow } from "../..";
import FormRowSelect from "../../FormRowSelect";
import { clearValues, createUser, handleChange, editUser } from "../../../features/user/userSlice";
import { getNotIncludedAuthEmployees } from "../../../features/allEmployees/allEmployeesSlice";
import userSlice from "../../../features/user/userSlice";
import { useGlobalContext } from '../../context';
import SelectAsyncPaginateEmployees from "../../employee/allEmployees/SelectAsyncPaginateEmployees";
import { getAllRoles } from "../../../features/allRoles/allRolesSlice";

const EditUser = () => {
  const {
    rec_Id,
    person_Rec_Id,
    firstName,
    lastName,
    person_Id,
    email,
    role_Id,
    createdBy,
    updatedBy,
    isEditing,
    editUserRecId,
  } = useSelector((store) => store.user);
  const { user, isLoading } = useSelector((store) => store.user);
  const dispatch = useDispatch();

    const [selectedEmployees, setSelectedEmployees] = useState([]);

    const { roles, areRolesLoading } = useSelector((store) => store.allRoles);
    const role_list = roles.map((role) => {
        return { label: role.role_Id, value: role.role_Id };
    });

    useEffect(() => {
        dispatch(getAllRoles()).then((response) => {
            dispatch(handleChange({ name: 'role_Id', value: response.payload.roles.length > 0 ? response.payload.roles[0].role_Id : '' }));
        });;
    }, []);

    const handleSearch = (e) => {

        if (areRolesLoading) return;
        dispatch(handleChange({ name: e.target.name, value: e.target.value }));
    };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!selectedEmployees)
    {
      toast.error("Por favor llenar todos los datos.");
      return;
    }

      dispatch(createUser({person_Rec_Id: selectedEmployees.person_Rec_Id, role_Id: role_Id} ));
          //closeModal();
      
  }

  const handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    dispatch(handleChange({ name, value }));
  };

  return (
    <Wrapper>
      <form className="form">
        <h3>{isEditing ? 'Información del usuario' : 'Agregar usuario'}</h3>

              <div className="form-center">
                  <div className="form-row">
                    <label className='form-label'>
                        Colaborador
                    </label>
                    <SelectAsyncPaginateEmployees
                              value={selectedEmployees}
                              onChange={setSelectedEmployees}
                              methodAPI={getNotIncludedAuthEmployees}
                              isMulti={false}
                    />
                </div>
            <FormRowSelect
                labelText='Rol'
                name='role_Id'
                value={role_Id}
                handleChange={handleSearch}
                list={role_list}
            />
          {/* btn container */}
          <div className='btn-containner'>



            <button
              type='submit'
              className='btn btn-block submit-btn'
              onClick={handleSubmit}
              disabled={isLoading}
            >
              Guardar
            </button>
          </div>
        </div>
      </form>
    </Wrapper>
  );
};

export default EditUser;