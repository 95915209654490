import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { store } from "../store";
import { Children } from "react";
import { useMsal } from "@azure/msal-react";


const ProtectedRoute = ({ children }) => {
    const { user } = useSelector((store) => store.user);
    const { instance } = useMsal();

    

    if (!user) {
        const account = instance.getAllAccounts()[0];

        const logoutRequest = {
            account: account,
            postLogoutRedirectUri: "https://amparking-webapp-d365fo-uat.azurewebsites.net/landing",
        };

        if (account) {
            return instance.logoutRedirect(logoutRequest);
        }
        else {
            return <Navigate to='/landing' />
        }
    }
  return children;
};

export default ProtectedRoute;