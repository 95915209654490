import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import {
    getAllDepartmentsThunk,
    getDepartmentsRelatedWithUserThunk,
    showStatsThunk,
    getNotIncludedSlotDepartmentsThunk,
    getNotIncludedEmployeeDepartmentsThunk
} from './allDepartmentsThunk';

const initialFiltersState = {
    search: '',
 /* searchStatus: 'all',
  searchType: 'all',
  sort: 'latest',
  sortOptions: ['latest', 'oldest', 'a-z', 'z-a'],*/
};

const initialState = {
  areDepartmentsLoading: true,
  departments: [],
  totalDepartments: 0,
  numOfPages: 1,
  page: 1,
  stats: {},
  limit: 10,
  ...initialFiltersState,
};

export const getAllDepartments = createAsyncThunk('allDepartments/getDepartments', getAllDepartmentsThunk);
export const getDepartmentsRelatedWithUser = createAsyncThunk('allDepartments/getDepartmentsRelatedWithUser', getDepartmentsRelatedWithUserThunk);
export const getNotIncludedSlotDepartments = createAsyncThunk('allDepartments/getNotIncludedSlotDepartments', getNotIncludedSlotDepartmentsThunk);
export const getNotIncludedEmployeeDepartments = createAsyncThunk('allDepartments/getNotIncludedEmployeeDepartments', getNotIncludedEmployeeDepartmentsThunk);


export const showStats = createAsyncThunk('allDepartments/showStats', showStatsThunk);

const allDepartmentsSlice = createSlice({
  name: 'allDepartments',
  initialState,
  reducers: {
    showLoading: (state) => {
      state.areDepartmentsLoading = true;
    },
    hideLoading: (state) => {
      state.areDepartmentsLoading = false;
    },
    handleChange: (state, { payload: { name, value } }) => {
      state.page = 1;
      state[name] = value;
    },
    clearFilters: (state) => {
      return { ...state, ...initialFiltersState };
    },
    changePage: (state, { payload }) => {
      state.page = payload;
    },
    clearAllDepartmentsState: (state) => initialState,
    
  },
  extraReducers: {
    [getAllDepartments.pending]: (state) => {
        state.areDepartmentsLoading = true;
    },
      [getAllDepartments.fulfilled]: (state, { payload }) => {
        state.areDepartmentsLoading = false;
        state.departments = payload.departments;
        state.numOfPages = payload.numOfPages;
        state.totalDepartments = payload.totalDepartments;

    },
    [getAllDepartments.rejected]: (state, { payload }) => {
        state.areDepartmentsLoading = false;
        toast.error(payload);
      },
      [getDepartmentsRelatedWithUser.pending]: (state) => {
          state.areDepartmentsLoading = true;
      },
      [getDepartmentsRelatedWithUser.fulfilled]: (state, { payload }) => {
          state.areDepartmentsLoading = false;
          state.departments = payload.departments;
          state.numOfPages = payload.numOfPages;
          state.totalDepartments = payload.totalDepartments;

      },
      [getDepartmentsRelatedWithUser.rejected]: (state, { payload }) => {
          state.areDepartmentsLoading = false;
          toast.error(payload);
      },
    [getNotIncludedSlotDepartments.pending]: (state) => {
        state.areDepartmentsLoading = true;
    },
    [getNotIncludedSlotDepartments.fulfilled]: (state, { payload }) => {
        state.areDepartmentsLoading = false;
        state.departments = payload.departments;
        state.numOfPages = payload.numOfPages;
        state.totalDepartments = payload.totalDepartments;


    },
    [getNotIncludedSlotDepartments.rejected]: (state, { payload }) => {
        state.areDepartmentsLoading = false;
        toast.error(payload);
    },
    [getNotIncludedEmployeeDepartments.pending]: (state) => {
        state.areDepartmentsLoading = true;
    },
    [getNotIncludedEmployeeDepartments.fulfilled]: (state, { payload }) => {
        state.areDepartmentsLoading = false;
        state.departments = payload.departments;
        state.numOfPages = payload.numOfPages;
        state.totalDepartments = payload.totalDepartments;

    },
    [getNotIncludedEmployeeDepartments.rejected]: (state, { payload }) => {
        state.areDepartmentsLoading = false;
        toast.error(payload);
    },
    [showStats.pending]: (state) => {
        state.areDepartmentsLoading = true;
    },
    [showStats.fulfilled]: (state, { payload }) => {
        state.areDepartmentsLoading = false;
        state.stats = payload.defaultStats;
        state.monthlyApplications = payload.monthlyApplications;
    },
    [showStats.rejected]: (state, { payload }) => {
        state.areDepartmentsLoading = false;
        toast.error(payload);
    },
  },
});


export const {
  showLoading,
  hideLoading,
  handleChange,
  clearFilters,
  changePage,
  clearAllDepartmentsState,
} = allDepartmentsSlice.actions;

export default allDepartmentsSlice.reducer;
