import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import {
  addUserToLocalStorage,
  getUserFromLocalStorage,
  removeUserFromLocalStorage,
} from '../../utils/localStorage';
import {
  loginUserThunk,
  registerUserThunk,
  updateUserThunk,
    clearStoreThunk,
    deleteUserThunk,
    editUserThunk,
  createUserThunk,
} from './userThunk';

const initialState = {
  isLoading: false,
isSidebarOpen: false,
  role_Id: '',
  user: getUserFromLocalStorage(),
};

export const registerUser = createAsyncThunk(
  'user/registerUser',
  async (user, thunkAPI) => {
    return registerUserThunk('/auth/register', user, thunkAPI);
  }
);

export const loginUser = createAsyncThunk(
  'user/loginUser',
  async (user, thunkAPI) => {
    return loginUserThunk('/Auth/Login', user, thunkAPI);
  }
);

export const updateUser = createAsyncThunk(
  'user/updateUser',
  async (user, thunkAPI) => {
    return updateUserThunk('/auth/updateUser', user, thunkAPI);
  }
);
export const clearStore = createAsyncThunk('user/clearStore', clearStoreThunk);
export const createUser = createAsyncThunk('user/createUser', createUserThunk);
export const deleteUser = createAsyncThunk('user/deleteUser', deleteUserThunk);
export const editUser = createAsyncThunk('user/editUser', editUserThunk);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    toggleSidebar: (state) => {
      state.isSidebarOpen = !state.isSidebarOpen;
    },
    handleChange: (state, { payload: { name, value } }) => {
        state[name] = value;
    },
    setEditUser: (state, { payload }) => {
        return { ...state, isEditing: true, ...payload };
    },
      clearValues: () => {
          return {
              ...initialState,
              //jobLocation: getUserFromLocalStorage()?.location || '',
          };
      },
    logoutUser: (state, { payload }) => {
      state.user = null;
      state.isSidebarOpen = false;
      removeUserFromLocalStorage();
      if (payload) {
        toast.success(payload);
      }
    },

      saveUserToken: (state, { payload }) => {
          state.user = { userToken: payload.token };
          addUserToLocalStorage(state.user);
    },
  },
  extraReducers: {
    [registerUser.pending]: (state) => {
      state.isLoading = true;
    },
    [registerUser.fulfilled]: (state, { payload }) => {
      const { user } = payload;
      state.isLoading = false;
      state.user = user;
      addUserToLocalStorage(user);
      toast.success(`Hello There ${user.firstname}`);
    },
    [registerUser.rejected]: (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload);
    },
    [loginUser.pending]: (state) => {
      state.isLoading = true;
    },
    [loginUser.fulfilled]: (state, { payload }) => {
      const { user } = payload;
      state.isLoading = false;
      state.user = user;
      addUserToLocalStorage(user);

      if (user.rec_Id != 0)
          toast.success(`Bienvenid@ ${user.firstName}`);
        else
          toast.error("Usuario no autorizado");
    },
    [loginUser.rejected]: (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload);
    },
    [updateUser.pending]: (state) => {
      state.isLoading = true;
    },
    [updateUser.fulfilled]: (state, { payload }) => {
      const { user } = payload;
      state.isLoading = false;
      state.user = user;
      addUserToLocalStorage(user);

      toast.success(`User Updated!`);
    },
    [updateUser.rejected]: (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload);
      },
    [deleteUser.pending]: (state) => {
        state.isLoading = true;
    },
    [deleteUser.fulfilled]: (state, { payload }) => {
        state.isLoading = false;
        toast.success(payload);
    },
    [deleteUser.rejected]: (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
    },
    [clearStore.rejected]: () => {
      toast.error('There was an error..');
    },
  },
});

export const { toggleSidebar, logoutUser, setEditUser, clearValues, handleChange } = userSlice.actions;
export default userSlice.reducer;
