import { FaLocationArrow, FaAt, FaCalendarAlt, FaPhone, FaIdCard } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Wrapper from '../../assets/wrappers/Department';
import { useDispatch, useSelector } from 'react-redux';
import DepartmentInfo from './DepartmentInfo';
import moment from 'moment';
import { setEditDepartment } from '../../features/department/departmentSlice';

const Department = ({
  rec_Id,
  name,
  createdDateTime,
    createdBy,
  onDelete,
}) => {
  const dispatch = useDispatch();
    const { user } = useSelector(store => store.user);
  const date = moment(createdDateTime).format('MMM Do, YYYY');


    const deleteRecord = () => {
        // if (typeof props.onChange === "function") {
        onDelete(rec_Id, name);
        
    };
    return (
        <Wrapper>
            <header>
                <div className='main-icon'>{rec_Id}</div>
                <div className='info'>
                    <h5>{name}</h5>
                    <p>{rec_Id}</p>
                </div>
            </header>
            <div className='content'>
                <div className='content-center'>
                    <DepartmentInfo icon={<FaIdCard />} text={createdBy} />
                    <DepartmentInfo icon={<FaCalendarAlt />} text={date} />
                </div>
                <footer>
                    <div className='actions'>
                        {user.role_Id == "Admin" ?
                            <Link
                                to='/edit-department'
                                className='btn edit-btn'
                                onClick={() =>
                                    dispatch(
                                        setEditDepartment({
                                            editDepartmentRecId: rec_Id,
                                            name,
                                            createdDateTime,
                                            createdBy,
                                        })
                                    )
                                }
                            >
                                Editar
                            </Link>
                            :
                            ""
                        }
                        {onDelete ?
                            <button
                                type='button'
                                className='btn delete-btn'
                                onClick={deleteRecord/*() => dispatch(deleteDepartment(rec_Id))*/}
                            >
                                Eliminar
                            </button>
                            :
                            ""
                        }
                    </div>
                </footer>
            </div>
        </Wrapper>
    );
};
export default Department;
