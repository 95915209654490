import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Wrapper from "../../../assets/wrappers/DashboardFormPage";
import { FormRow } from "../..";
import FormRowSelect from "../../FormRowSelect";
import { clearValues, createDepartment, handleChange, editDepartment } from "../../../features/department/departmentSlice";
import userSlice from "../../../features/user/userSlice";
import { useGlobalContext } from '../../context';


const EditDepartment = () => {
  const {
    isLoading,
    rec_Id,
    name,
    createdDateTime,
    updatedDateTime,
    createdBy,
    updatedBy,
    isEditing,
    editDepartmentRecId,
  } = useSelector((store) => store.department);
  const { user } = useSelector((store) => store.user);
  const dispatch = useDispatch();


  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (!name)
    {
      toast.error("Por favor llenar todos los datos.");
      return;
    }

      if (isEditing) {
          dispatch(editDepartment({ departmentRecId: editDepartmentRecId, department: {name} }));
      }
      else {
          dispatch(createDepartment({ name }));
      }
  }

  const handleDepartmentInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    dispatch(handleChange({ name, value }));
  };

 /* useEffect(() => {
    dispatch(handleChange ({
      name: 'jobLocation', 
      value:user.location
    }))
  });*/

  return (
    <Wrapper>
      <form className="form">
        <h3>{isEditing ? 'Información del departamento' : 'Agregar departamento'}</h3>

        <div className="form-center">
          {/* department_name */}
          <FormRow
                      type='text'
                      labelText='Nombre'
                      name='name'
                      value={name}
                      handleChange={handleDepartmentInput}
                      disabled={user.role_Id != "Admin"}
          />


          

          {/* btn container */}
          <div className='btn-containner'>
            {/*<button
              type='button'
              className='btn btn-block clear-btn'
              onClick={() => dispatch(clearValues())}
            >
              clear
            </button>*/}
            {user.role_Id == "Admin" ?
                <button
                    type='submit'
                    className='btn btn-block submit-btn'
                    onClick={handleSubmit}
                    disabled={isLoading}
                >
                    Guardar
                </button>
                :
                ""
            }
          </div>
        </div>
      </form>
    </Wrapper>
  );
};

export default EditDepartment;