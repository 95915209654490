import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { getUserFromLocalStorage } from '../../utils/localStorage';
import {
    createSlotThunk,
    deleteSlotThunk,
    editSlotThunk,
    getSlotDepartmentsThunk,
    addDepartmentsToSlotThunk,
    getSlotEmployeesThunk,
    addEmployeesToSlotThunk,
    deleteSlotDepartmentThunk,
    deleteSlotEmployeeThunk,
} from './slotThunk';


const initialEmployeesFiltersState = {
    employeeSearch: '',
    /* searchStatus: 'all',
     searchType: 'all',
     sort: 'latest',
     sortOptions: ['latest', 'oldest', 'a-z', 'z-a'],*/
};

const initialDepartmentsFiltersState = {
    departmentSearch: '',
    /* searchStatus: 'all',
     searchType: 'all',
     sort: 'latest',
     sortOptions: ['latest', 'oldest', 'a-z', 'z-a'],*/
};

const initialState = {
    isLoading: false,
    slot_Id: '',
    coordX: 0,
    coordY: 0,
    floor: 0,
    available: 1,
    used_By: '',
    createdDateTime: '',
    updatedDateTime: '',
    createdBy: '',
    updatedBy: '',
    isEditing: false,
    editSlotRecId: '',
    areDepartmentsLoading: true,
    departments: [],
    totalDepartments: 0,
    numOfDepartmentPages: 1,
    departmentsPage: 1,
    departmentsLimit: 10,
    areEmployeesLoading: true,
    employees: [],
    totalEmployees: 0,
    numOfEmployeesPages: 1,
    employeesPage: 1,
    employeesLimit: 10,
    ...initialDepartmentsFiltersState,
    ...initialEmployeesFiltersState,
};


export const createSlot = createAsyncThunk('slot/createSlot', createSlotThunk);
export const deleteSlot = createAsyncThunk('slot/deleteSlot', deleteSlotThunk);
export const editSlot = createAsyncThunk('slot/editSlot', editSlotThunk);
export const addDepartmentsToSlot = createAsyncThunk('slot/addDepartments', addDepartmentsToSlotThunk);
export const addEmployeesToSlot = createAsyncThunk('slot/addEmployees', addEmployeesToSlotThunk);
export const getSlotDepartments = createAsyncThunk('slot/getSlotDepartments', getSlotDepartmentsThunk); 
export const getSlotEmployees = createAsyncThunk('slot/getSlotEmployees', getSlotEmployeesThunk);
export const deleteSlotDepartment = createAsyncThunk('slot/deleteDepartment', deleteSlotDepartmentThunk);
export const deleteSlotEmployee = createAsyncThunk('slot/deleteEmployee', deleteSlotEmployeeThunk);




const slotSlice = createSlice({
  name: 'slice',
  initialState,
  reducers: {
    handleChange: (state, { payload: { name, value } }) => {
      state[name] = value;
    },
    clearValues: () => {
      return {
        ...initialState,
      };
    },
    setEditSlot: (state, { payload }) => {
      return { ...state, isEditing: true, ...payload };
      },

    /*departments*/
    showDepartmentsLoading: (state) => {
        state.areDepartmentsLoading = true;
    },
    hideDepartmentsLoading: (state) => {
        state.areDepartmentsLoading = false;
    },
    handleDepartmentsChange: (state, { payload: { name, value } }) => {
        state.departmentsPage = 1;
        state[name] = value;
    },
    clearDepartmentsFilters: (state) => {
        return { ...state, ...initialDepartmentsFiltersState };
    },
    changeDepartmentsPage: (state, { payload }) => {
        state.departmentsPage = payload;
      },

      /*employees*/
      showEmployeesLoading: (state) => {
          state.areEmployeesLoading = true;
      },
      hideEmployeesLoading: (state) => {
          state.areEmployeesLoading = false;
      },
      handleEmployeesChange: (state, { payload: { name, value } }) => {
          state.employeesPage = 1;
          state[name] = value;
      },
      clearEmployeesFilters: (state) => {
          return { ...state, ...initialEmployeesFiltersState };
      },
      changeEmployeesPage: (state, { payload }) => {
          state.employeesPage = payload;
      },
  },
  extraReducers: {
    [createSlot.pending]: (state) => {
      state.isLoading = true;
    },
    [createSlot.fulfilled]: (state) => {
      state.isLoading = false;
      toast.success('Espacio creado');
    },
    [createSlot.rejected]: (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload);
    },
    [deleteSlot.fulfilled]: (state, { payload }) => {
        toast.success('Espacio eliminado');
        clearValues();
    },
    [deleteSlot.rejected]: (state, { payload }) => {
      toast.error(payload);
    },
    [editSlot.pending]: (state) => {
      state.isLoading = true;
    },
      [editSlot.fulfilled]: (state) => {
          state.isLoading = false;
          toast.success(`Espacio ${state.slot_Id} modificado...`);
    },
    [editSlot.rejected]: (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      },

    /**
     * 
     *DEPARTMENTS
     */
    [getSlotDepartments.pending]: (state) => {
        state.areDepartmentsLoading = true;
    },
    [getSlotDepartments.fulfilled]: (state, { payload }) => {
        state.areDepartmentsLoading = false;
        state.departments = payload.departments;
        state.numOfDepartmentPages = payload.numOfPages;
        state.totalDepartments = payload.totalDepartments;
    },
    [getSlotDepartments.rejected]: (state, { payload }) => {
    state.areDepartmentsLoading = false;
    toast.error(payload);
    },
      [addDepartmentsToSlot.pending]: (state) => {
        state.areDepartmentsLoading = true;
    },
      [addDepartmentsToSlot.fulfilled]: (state) => {
        state.areDepartmentsLoading = false;
        toast.success('Departamentos agregados');
    },
      [addDepartmentsToSlot.rejected]: (state, { payload }) => {
        state.areDepartmentsLoading = false;
        toast.error(payload);
      },
      [deleteSlotDepartment.pending]: (state) => {
          state.areDepartmentsLoading = true;
      },
      [deleteSlotDepartment.fulfilled]: (state) => {
          state.areDepartmentsLoading = false;
          toast.success('Departamento eliminado');
      },
      [deleteSlotDepartment.rejected]: (state, { payload }) => {
          state.areDepartmentsLoading = false;
          toast.error(payload);
      },

      /*EMPLOYEES*/
      [getSlotEmployees.pending]: (state) => {
          state.areEmployeesLoading = true;
      },
      [getSlotEmployees.fulfilled]: (state, { payload }) => {
          state.areEmployeesLoading = false;
          state.employees = payload.employees;
          state.numOfEmployeesPages = payload.numOfPages;
          state.totalEmployees = payload.totalEmployees;
      },
      [getSlotEmployees.rejected]: (state, { payload }) => {
          state.areEmployeesLoading = false;
          toast.error(payload);
      },
      [addEmployeesToSlot.pending]: (state) => {
          state.areEmployeesLoading = true;
      },
      [addEmployeesToSlot.fulfilled]: (state) => {
          state.areEmployeesLoading = false;
          toast.success('Colaboradores agregados');
      },
      [addEmployeesToSlot.rejected]: (state, { payload }) => {
          state.areEmployeesLoading = false;
          toast.error(payload);
      },
      [deleteSlotEmployee.pending]: (state) => {
          state.areEmployeesLoading = true;
      },
      [deleteSlotEmployee.fulfilled]: (state) => {
          state.areEmployeesLoading = false;
          toast.success('Colaborador eliminado');
      },
      [deleteSlotEmployee.rejected]: (state, { payload }) => {
          state.areEmployeesLoading = false;
          toast.error(payload);
      },
  },
});

export const {
    handleChange,
    clearValues,
    setEditSlot,
    showDepartmentsLoading,
    hideDepartmentsLoading,
    handleDepartmentsChange,
    clearDepartmentsFilters,
    changeDepartmentsPage,
    showEmployeesLoading,
    hideEmployeesLoading,
    handleEmployeesChange,
    clearEmployeesFilters,
    changeEmployeesPage,
} = slotSlice.actions;

export default slotSlice.reducer;
