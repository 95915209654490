import { CalendarContainer, } from '../../components';
import { AppProvider } from '../../components/context';
import { SearchContainerSlots } from '../../components';



const Calendar = () => {
  return (
      <>
          <SearchContainerSlots filterByDepartment={true} title="Calendario" />
          <AppProvider><CalendarContainer /></AppProvider>
    </> 
  );
};
export default Calendar;
