import { useState, useEffect } from "react";
import { FormRow, Logo } from '../components';
import Wrapper from '../assets/wrappers/RegisterPage';
import { toast } from 'react-toastify';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { loginUser, registerUser } from "../features/user/userSlice";
import { useNavigate } from "react-router-dom";

const initialState = {
  name: '',
  email: '',
  password: '',
  isMember: true,
};

function Register() {
  const [values, setValues] = useState(initialState);

  const {user, isLoading} = useSelector(store => store.user)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setValues({...values, [name]: value});
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const { name, email, password, isMember } = values;
    /*if (!email || !password)
    {
      toast.error("Por favor llenar todos los campos");
      return;
    }*/
    /*if (isMember)
    {*/
      dispatch(loginUser({ email: email, password: password }));
      return;
   // }
    //dispatch(registerUser({ name, email, password }));
  };

  const toggleMember = () => {
    setValues({ ...values, isMember: !values.isMember });
  };

  useEffect(() => {
    if (user)
    {
      setTimeout(() => {
        navigate('/');
      }, 2000);
    }
  }, [user]);
  
  return (
    <Wrapper className="full-page">
      <form className="form" onSubmit={onSubmit}>
        <h3>{values.isMember ? "Login" : "Register"}</h3>

        {/*Name field */}
        {!values.isMember && (
          <FormRow 
            type="text" 
            name="name" 
            value={values.name} 
            handleChange={handleChange}
          />
        )}

        {/* email field */}
        <FormRow 
          type="email" 
          name="email" 
          value={values.email} 
          handleChange={handleChange}
        />

        {/*password field */}
        <FormRow 
          type="password" 
          name="password" 
          value={values.password} 
          handleChange={handleChange}
        />

        <button type="submit" className="btn btn-block" disabled={isLoading}>
          {isLoading ? "loading..." : "Login"}
        </button>
        <p>

        </p>
      </form>
    </Wrapper>
  );
}
export default Register;