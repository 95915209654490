import { HiChevronDoubleLeft, HiChevronDoubleRight } from 'react-icons/hi';
import Wrapper from '../../../assets/wrappers/PageBtnContainer';
import { useSelector, useDispatch } from 'react-redux';
import { changeDepartmentsPage } from '../../../features/employee/employeeSlice';

const EmployeeDepartmentsPageBtnContainer = () => {
    const { numOfDepartmentPages, departmentsPage } = useSelector((store) => store.employee);
  const dispatch = useDispatch();

  const pages = Array.from({ length: numOfDepartmentPages }, (_, index) => {
    return index + 1;
  });

  const nextPage = () => {
      let newPage = departmentsPage + 1;
    if (newPage > numOfDepartmentPages) {
      newPage = 1;
    }
      dispatch(changeDepartmentsPage(newPage));
  };
  const prevPage = () => {
      let newPage = departmentsPage - 1;
    if (newPage < 1) {
      newPage = numOfDepartmentPages;
    }
      dispatch(changeDepartmentsPage(newPage));
  };

  return (
    <Wrapper>
      <button type='button' className='prev-btn' onClick={prevPage}>
        <HiChevronDoubleLeft />
        prev
      </button>
      <div className='btn-container'>
        {pages.map((pageNumber) => {
          return (
            <button
              type='button'
              key={pageNumber}
                  className={pageNumber === departmentsPage ? 'pageBtn active' : 'pageBtn'}
                  onClick={() => dispatch(changeDepartmentsPage(pageNumber))}
            >
              {pageNumber}
            </button>
          );
        })}
      </div>
      <button type='button' className='next-btn' onClick={nextPage}>
        next
        <HiChevronDoubleRight />
      </button>
    </Wrapper>
  );
};
export default EmployeeDepartmentsPageBtnContainer;
