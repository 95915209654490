import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Wrapper from "../../../assets/wrappers/DashboardFormPage";
import { FormRow } from "../..";
import FormRowSelect from "../../FormRowSelect";
import { clearValues, createFloor, handleChange, editFloor } from "../../../features/floor/floorSlice";
import userSlice from "../../../features/user/userSlice";
import { useGlobalContext } from '../../context';


const EditFloor = () => {
  const {
    isLoading,
    rec_Id,
    floor_Id,
    createdDateTime,
    updatedDateTime,
    createdBy,
    updatedBy,
    isEditing,
    editFloorRecId,
  } = useSelector((store) => store.floor);
  const { user } = useSelector((store) => store.user);
  const dispatch = useDispatch();
    //const { closeModal } = useGlobalContext();

  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (!floor_Id)
    {
      toast.error("Por favor llenar todos los datos.");
      return;
    }

      if (isEditing) {
          dispatch(editFloor({ floorRecId: editFloorRecId, floor: { floor_Id, createdDateTime, updatedDateTime, createdBy, updatedBy } }));
      }
      else {
          dispatch(createFloor({ floor_Id }));
          //closeModal();
      }
  }

  const handleFloorInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    dispatch(handleChange({ name, value }));
  };

 /* useEffect(() => {
    dispatch(handleChange ({
      name: 'jobLocation', 
      value:user.location
    }))
  });*/

  return (
    <Wrapper>
      <form className="form">
        <h3>{isEditing ? 'Información del piso' : 'Agregar piso'}</h3>

        <div className="form-center">
          {/* floor_name */}
          <FormRow
            type='text'
            labelText='Identificador del piso'
            name='floor_Id'
            value={floor_Id}
            handleChange={handleFloorInput}
          />


          

          {/* btn container */}
          <div className='btn-containner'>
            {/*<button
              type='button'
              className='btn btn-block clear-btn'
              onClick={() => dispatch(clearValues())}
            >
              clear
            </button>*/}
            <button
              type='submit'
              className='btn btn-block submit-btn'
              onClick={handleSubmit}
              disabled={isLoading}
            >
              Guardar
            </button>
          </div>
        </div>
      </form>
    </Wrapper>
  );
};

export default EditFloor;