import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import main from '../assets/images/main.svg';
import Wrapper from '../assets/wrappers/LandingPage';
import { Logo } from '../components';
import { useMsal } from "@azure/msal-react";
import { loginUser } from "../features/user/userSlice";
import { useNavigate } from "react-router-dom";

//const msalInstance = new PublicClientApplication(msalConfig);

const AuthRedirect = () => {
    const { instance } = useMsal();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user, isLoading } = useSelector(store => store.user)

    instance.handleRedirectPromise().then((response) => {
        if (response) {
            dispatch(loginUser({ Email: response.username })).then((response) => {

                if (user) {
                    setTimeout(() => {
                        navigate('/');
                    }, 2000);
                }
            });
        }
    });

    /*useEffect(() => {

        if (account) {
            dispatch(loginUser({ Email: account.username })).then((resp) => {
                console.log(resp.payload.success);
            });
        }
    }, [instance.getAllAccounts()[0]);*/

    useEffect(() => {
        if (user) {
            setTimeout(() => {
                navigate('/');
            }, 2000);
        }

    }, [instance]);

    return (
        <Wrapper>
            <nav>
                <Logo />
            </nav>

            <div className='container page'>
                <div className='info'>
                    <h1>
                       <span>Autenticando...</span>
                    </h1>
                </div>
                <img src={main} alt='job hunt' className='img main-img' />
            </div>
        </Wrapper>
    );
};



export default AuthRedirect;
