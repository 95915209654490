import { showLoading, hideLoading, getAllEmployees } from '../allEmployees/allEmployeesSlice';
import { getEmployeeDepartments } from '../employee/employeeSlice';
import customFetch, { checkForUnauthorizedResponse } from '../../utils/axios';
import { clearValues } from './employeeSlice';
import Floor from '../../components/floor/Floor';

export const createEmployeeThunk = async (employee, thunkAPI) => {
    try {
        console.log(employee);
    const resp = await customFetch.post('/Employee', employee);
    thunkAPI.dispatch(getAllEmployees());
    return resp.data.msg;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};
export const deleteEmployeeThunk = async (employeeRecId, thunkAPI) => {
  thunkAPI.dispatch(showLoading());
  try {
    const resp = await customFetch.delete(`/Employee/${employeeRecId}`);
    thunkAPI.dispatch(getAllEmployees());
    return resp.data.msg;
  } catch (error) {
    thunkAPI.dispatch(hideLoading());
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const editEmployeeThunk = async ({ employeeRecId, employee }, thunkAPI) => {
    try {
    const resp = await customFetch.patch(`/Employee/${employeeRecId}`, employee);
    //thunkAPI.dispatch(clearValues());
    return resp.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};

/**
 * *
 * @param {any} employeeRecId
 * @param {any} thunkAPI
 */
export const getEmployeeSlotsThunk = async (employeeRecId, thunkAPI) => {

    const { floorSearch } =
    thunkAPI.getState().employee;

    let url = `/Employee/Allocations?employee=${employeeRecId}&floor=${floorSearch}`;

    try {
      const resp = await customFetch.get(url);
      return resp.data;
    } catch (error) {
      return checkForUnauthorizedResponse(error, thunkAPI);
    }
};

/**
 * *
 * @param {any} employeeRecId
 * @param {any} thunkAPI
 */
export const getEmployeeDepartmentsThunk = async (employeeRecId, thunkAPI) => {
    const { departmentsPage, departmentSearch, departmentsLimit } =
        thunkAPI.getState().employee;

    if (!departmentsLimit)
        departmentsLimit = 10;

    let url = `/Employee/Departments/${employeeRecId}?page=${departmentsPage}&limit=${departmentsLimit}`;
    if (departmentSearch) {
        url = url + `&search=${departmentSearch}`;
    }
    else {
        url = url + `&search=""`;
    }

    try {
        const resp = await customFetch.get(url);
        return resp.data;
    } catch (error) {
        return checkForUnauthorizedResponse(error, thunkAPI);
    }
};

/**
 * 
 * @param {any} employeeRecId
 * @param {any} thunkAPI
 */
export const assignEmployeeSlotThunk = async (employee, thunkAPI) => {
    try {
        const resp = await customFetch.post(`/Employee/AssignSlot`, employee);
        return resp.data.msg;
    } catch (error) {
        return checkForUnauthorizedResponse(error, thunkAPI);
    }
};

/**
 * 
 * @param {any} param0
 * @param {any} thunkAPI
 */
export const addDepartmentsToEmployeeThunk = async ({ employeeRecId, departments }, thunkAPI) => {
    try {
        const resp = await customFetch.patch(`/Employee/Departments/AddDepartments/${employeeRecId}`, departments);
        thunkAPI.dispatch(getEmployeeDepartments(employeeRecId));
        return resp.data;
    } catch (error) {
        return checkForUnauthorizedResponse(error, thunkAPI);
    }
};

/**
 * *
 * @param {any} param0
 * @param {any} thunkAPI
 */
export const deleteEmployeeDepartmentThunk = async ({ employeeRecId, departmentRecId }, thunkAPI) => {
    try {
        const resp = await customFetch.delete(`/Employee/Departments/DeleteDepartment/${employeeRecId}?department=${departmentRecId}`);
        thunkAPI.dispatch(getEmployeeDepartments(employeeRecId));
        return resp.data;
    } catch (error) {
        return checkForUnauthorizedResponse(error, thunkAPI);
    }
};

/**
 * 
 * @param {any} employeeRecId
 * @param {any} day
 * @param {any} thunkAPI
 */
export const getEmployeeInfoThunk = async ({ person_Id, day }, thunkAPI) => {

    let url = `/Employee/${person_Id}?day=${day}`;

    try {
        const resp = await customFetch.get(url);
        return resp.data;
    } catch (error) {
        return checkForUnauthorizedResponse(error, thunkAPI);
    }
}