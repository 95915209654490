import React from 'react';
import ReactDOM from 'react-dom/client';
import 'normalize.css'
import './index.css';
import App from './App';
import { store } from './store';
import { Provider } from 'react-redux';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import axios from "axios";


const msalInstance = new PublicClientApplication(msalConfig);

/*msalInstance.handleRedirectPromise().then((response) => {
    if (response) {
        console.log(response); // Account object representing the signed-in user
        console.log(response.idTokenClaims.email); // User's email address
    }
});*/



const root = ReactDOM.createRoot(document.getElementById('root'));


//axios.defaults.baseURL = "https://localhost:7255/api/";


root.render(
    <React.StrictMode>
        <MsalProvider instance={msalInstance}>
            <Provider store={store}>
                <App tab='home' />
            </Provider>
        </MsalProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
