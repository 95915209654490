import styled from 'styled-components'

const Wrapper = styled.section`
  .form {
    width: 100%;
    max-width: 100%;
  }
  .form-input,
  .form-select,
  .btn-block {
    height: 35px;
  }
  .form-row {
    margin-bottom: 0;
  }
  .form-center {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 2rem;
    row-gap: 0.5rem;
  }
  h5 {
    font-weight: 700;
  }
  .btn-block {
    align-self: end;
    margin-top: 1rem;
  }
    .card {
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
      padding: 20px;
      margin: 20px;
      width: 300px;
      max-width: 500px;
      text-align: center;
      
    }

    .card-description {
      color: #fff;
      background-color: #00a651;
      width: 100%;
      font-size: 1.6em;
      text-align: center;
      font-weight: 400;
      padding: 5px 0;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    .container {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  @media (min-width: 768px) {
    .form-center {
      grid-template-columns: 1fr 1fr;
    }
  }
  @media (min-width: 992px) {
    .form-center {
      grid-template-columns: 1fr 1fr 1fr;
    }
    .btn-block {
      margin-top: 0;
    }
  }
`

export default Wrapper
