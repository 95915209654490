import { HiChevronDoubleLeft, HiChevronDoubleRight } from 'react-icons/hi';
import Wrapper from '../../../assets/wrappers/PageBtnContainer';
import { useSelector, useDispatch } from 'react-redux';
import { changePage } from '../../../features/allEmployees/allEmployeesSlice';

const AllEmployeesPageBtnContainer = () => {
  const { numOfPages, page } = useSelector((store) => store.allEmployees);
  const dispatch = useDispatch();

  const pages = Array.from({ length: numOfPages }, (_, index) => {
    return index + 1;
  });

  const nextPage = () => {
    let newPage = page + 1;
    if (newPage > numOfPages) {
      newPage = 1;
    }
    dispatch(changePage(newPage));
  };
  const prevPage = () => {
    let newPage = page - 1;
    if (newPage < 1) {
      newPage = numOfPages;
    }
    dispatch(changePage(newPage));
  };

  return (
    <Wrapper>
      <button type='button' className='prev-btn' onClick={prevPage}>
        <HiChevronDoubleLeft />
        prev
      </button>
      <div className='btn-container'>
              {pages.map((pageNumber) => {
                  if (pageNumber + 10 < page) {
                      return (
                          <>
        
                              </>
                      );
                  }

                 
                  if (pageNumber > page + 10) return (<></>);
            return (
                <button
                  type='button'
                  key={pageNumber}
                  className={pageNumber === page ? 'pageBtn active' : 'pageBtn'}
                  onClick={() => dispatch(changePage(pageNumber))}
                >
                  {pageNumber}
                </button>
          );
        })}
      </div>
      <button type='button' className='next-btn' onClick={nextPage}>
        next
        <HiChevronDoubleRight />
      </button>
    </Wrapper>
  );
};
export default AllEmployeesPageBtnContainer;
