import customFetch, { checkForUnauthorizedResponse } from '../../utils/axios';

export const getAllFloorsThunk = async (_, thunkAPI) => {
    
  const { page, search } =
    thunkAPI.getState().allFloors;

    
  let url = `/Floor`;
  if (search) {
    //url = url + `&search=${search}`;
  }
  try {
    const resp = await customFetch.get(url);
    return resp.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const showStatsThunk = async (_, thunkAPI) => {
  try {
    const resp = await customFetch.get('/floors/stats');

    return resp.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};
