import { useEffect, useState } from 'react';
import Department from '../../department/Department';
import Wrapper from '../../../assets/wrappers/DepartmentsContainer';
import { useSelector, useDispatch } from 'react-redux';
import Loading from '../../Loading';
import { getEmployeeDepartments, addDepartmentsToEmployee, deleteEmployeeDepartment } from '../../../features/employee/employeeSlice';
import EmployeeDepartmentsPageBtnContainer from './EmployeeDepartmentsPageBtnContainer';
import { DepartmentInfoContainer } from '../../index';
import SelectAsyncPaginateDepartments from "../../department/allDepartments/SelectAsyncPaginateDepartments";
import Modal from '../../Modal';
import { useGlobalContext } from '../../context';
import { getNotIncludedEmployeeDepartments, getAllDepartments } from '../../../features/allDepartments/allDepartmentsSlice';
import { toast } from "react-toastify";
import swal from 'sweetalert';

const EmployeeDepartmentsContainer = () => {
    const {
        departments,
        areDepartmentsLoading,
        departmentsPage,
        totalDepartments,
        numOfDepartmentPages,
        departmentSearch,
        editRecId,
    } = useSelector((store) => store.employee);
    const dispatch = useDispatch();
    const { isModalOpen, openModal, closeModal } = useGlobalContext();
    const { user } = useSelector((store) => store.user);


    const [selectedDepartments, setSelectedDepartments] = useState([]);

    useEffect(() => {
        dispatch(getEmployeeDepartments(editRecId));
    }, [departmentsPage, departmentSearch]);


    const openModalAux = () => {
        //setSelectedDepartments([...selectedDepartments, ...departments]);
        openModal();

    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (selectedDepartments.length === 0) {
            toast.error("Por favor llenar todos los datos.");
            return;
        }
        closeModal();

        dispatch(addDepartmentsToEmployee({
            employeeRecId: editRecId,
            departments: selectedDepartments
        }));
    }

    const onDeleteDepartment = (departmentRecId, departmentName) => {
        swal({
            title: "\u00BF Seguro deseas excluir a este colaborador del departamento " + departmentName + "?",
            text: "Una vez eliminado, no ser\u00E1 posible recuperar la informaci\u00F3n",
            icon: "warning",
            buttons: {
                cancel: "Cancelar",
                confirm: "Eliminar",
            },
            dangerMode: true,
        })
        .then((willDelete) => {
            if (willDelete) {
                dispatch(deleteEmployeeDepartment({ employeeRecId: editRecId, departmentRecId }));
            }
        });

    }


    if (areDepartmentsLoading) {
        return <Loading />;
    }

    if (departments && departments.length === 0) {
        return (
            <Wrapper>
                {user.role_Id == "Security" ? "" :
                    <button onClick={openModalAux} className='btn'>
                        Agregar departamento
                    </button>
                }
                <h4></h4>
                <Modal>
                    <form className="form">
                        <h3>{'Agregar departamentos'}</h3>

                        <div className="form-center">
                            <SelectAsyncPaginateDepartments
                                value={selectedDepartments}
                                onChange={setSelectedDepartments}
                                methodAPI={getNotIncludedEmployeeDepartments}
                                sourceRecId={editRecId}
                            />
                            <button
                                className='btn btn-block submit-btn'
                                onClick={handleSubmit}
                                disabled={selectedDepartments.length === 0}
                            >
                                Agregar
                            </button>
                        </div>
                    </form>
                </Modal>
                <h2>No hay departamentos para mostrar...</h2>
            </Wrapper>
        );
    }



    return (
        <Wrapper>
            <h5>
                {totalDepartments} departamento{departments && departments.length > 1 && 's'} encontrado{departments && departments.length > 1 && 's'}
            </h5>
            <div className='departments'>
                {user.role_Id == "Security" ? "" :
                    <button onClick={openModalAux} className='btn'>
                        Agregar departamento
                    </button>
                }
                <Modal>
                    <form className="form">
                        <h3>{'Agregar departamentos'}</h3>

                        <div className="form-center">
                            <SelectAsyncPaginateDepartments
                                value={selectedDepartments}
                                onChange={setSelectedDepartments}
                                methodAPI={getNotIncludedEmployeeDepartments}
                                sourceRecId={editRecId}
                            />
                            <button
                                className='btn btn-block submit-btn'
                                onClick={handleSubmit}
                                disabled={selectedDepartments.length === 0}
                            >
                                Agregar
                            </button>
                        </div>
                    </form>
                </Modal>
                {departments ? departments.map((department) => {
                    return <Department key={department.rec_Id} onDelete={ user.role_Id == "Security" ? null : onDeleteDepartment } {...department} />;
                }) : ""}
            </div>
            {numOfDepartmentPages > 1 && <EmployeeDepartmentsPageBtnContainer />}
        </Wrapper>
    );
};
export default EmployeeDepartmentsContainer;
