import { toggleSidebar, logoutUser, clearStore } from '../features/user/userSlice';
import { useMsal } from "@azure/msal-react";
import Wrapper from '../assets/wrappers/Navbar';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { FaAlignLeft, FaCaretDown, FaHome, FaUserCircle } from 'react-icons/fa';
import  Logo   from './Logo';


const Navbar = () => {
    const [showLogout, setShowLogout] = useState(false);
    const {user} = useSelector((store) => store.user);
    const dispatch = useDispatch();
    const { instance } = useMsal();


    const handleLogout = () => {
        const account = instance.getAllAccounts()[0];
        const logoutRequest = {
            account: account,
            postLogoutRedirectUri: "https://amparking-webapp-d365fo-uat.azurewebsites.net/landing",
        };
        instance.logoutRedirect(logoutRequest);
        dispatch(clearStore('Logout Successful...'));
    }

  const toggle = () => {
    dispatch(toggleSidebar());
  };

  return (
    <Wrapper>
      <div className='nav-center'>
        <button type='button' className='toggle-btn' onClick={toggle}>
          <FaAlignLeft/>
        </button>
        <div>
        <Logo/>
        <h3 className='logo-text'>Panel de control</h3>
      </div>
      <div className='btn-container'>
        <button type='button' className='btn' onClick={() => setShowLogout(!showLogout)}>
          <FaUserCircle/>
          {user?.firstName}
          <FaCaretDown/>
        </button>
        <div className={showLogout ? 'dropdown show-dropdown' : 'dropdown'}>
          <button 
            type='button' 
            className='dropdown-btn'
            onClick={handleLogout}
          >
            Logout
          </button>
        </div>
      </div>
      </div>
      
    </Wrapper>
  );
};

export default Navbar;