import { NavLink } from 'react-router-dom';
import sideBarData from '../utils/sideBarData';
import SidebarItem from './SidebarItem';


export const NavLinks = ({ toggleSidebar }) => {
  return (
    <div className='nav-links'>
        {sideBarData.map((item) => {
        //const {text, path, id, icon} = link;
        return (
          
          <SidebarItem key={item.id} item={item} />
        );
         
        })}
    </div>
  );
};

export default NavLinks;
