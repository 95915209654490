import {
    EmployeeInfoContainer,
    EmployeeDepartmentsContainer,
    SearchContainerEmployeeDepartments,
    EmployeeCalendarContainer,
    SearchContainerEmployeeCalendar
} from '../../components';
import { AppProvider } from '../../components/context';


const EmployeeInfo = () => {
  return (
    <>
          <EmployeeInfoContainer />
          <SearchContainerEmployeeDepartments />
          <AppProvider><EmployeeDepartmentsContainer /></AppProvider>
          <SearchContainerEmployeeCalendar title={"Calendario"} />
          <EmployeeCalendarContainer/>
    </>
  );
};
export default EmployeeInfo;
