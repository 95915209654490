import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./features/user/userSlice";
import jobSlice from "./features/job/jobSlice";
import allJobsSlice from "./features/allJobs/allJobsSlice";
import slotSlice from "./features/slot/slotSlice";
import employeeSlice from "./features/employee/employeeSlice";
import allEmployeesSlice from "./features/allEmployees/allEmployeesSlice";
import allDepartmentsSlice from "./features/allDepartments/allDepartmentsSlice";
import allSlotsSlice from "./features/allSlots/allSlotsSlice";
import allFloorsSlice from "./features/allFloors/allFloorsSlice";
import departmentSlice from "./features/department/departmentSlice";
import floorSlice from "./features/floor/floorSlice";
import allWeekDaysSlice from "./features/allWeekDays/allWeekDaysSlice";
import allUsersSlice from "./features/allUsers/allUsersSlice";
import allRolesSlice from "./features/allRoles/allRolesSlice";
//import vaccineSlice from "./features/vaccine/vaccineSlice";

export const store = configureStore({
    reducer:{
        user: userSlice,
        job: jobSlice,
        allJobs: allJobsSlice,
        allEmployees: allEmployeesSlice,
        employee: employeeSlice,
        allSlots: allSlotsSlice,
        allDepartments: allDepartmentsSlice,
        slot: slotSlice,
        allFloors: allFloorsSlice,
        department: departmentSlice,
        floor: floorSlice,
        allWeekDays: allWeekDaysSlice,
        allUsers: allUsersSlice,
        allRoles: allRolesSlice,
        //vaccine: vaccineSlice,
    },
});