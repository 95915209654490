import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { getAllWeekDaysThunk, showStatsThunk, getWeekDaysAllocationsThunk } from './allWeekDaysThunk';


const initialState = {
    isLoading: true,
    weekDays: [],
};

export const getAllWeekDays = createAsyncThunk('allWeekDays/getWeekDays', getAllWeekDaysThunk);

const allWeekDaysSlice = createSlice({
  name: 'allWeekDays',
  initialState,
  reducers: {
    showLoading: (state) => {
      state.isLoading = true;
    },
    hideLoading: (state) => {
      state.isLoading = false;
    },
    handleChange: (state, { payload: { name, value } }) => {
      state.page = 1;
      state[name] = value;
    },

    clearAllWeekDaysState: (state) => initialState,
    
  },
  extraReducers: {
    [getAllWeekDays.pending]: (state) => {
      state.isLoading = true;
    },
    [getAllWeekDays.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
        state.weekDays = payload.weekDays;
    //  state.numOfPages = payload.numOfPages;
    //  state.totalWeekDays = payload.totalWeekDays;
    },
    [getAllWeekDays.rejected]: (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload);
     },
  },
});


export const {
  showLoading,
  hideLoading,
  handleChange,
  clearAllWeekDaysState,
} = allWeekDaysSlice.actions;

export default allWeekDaysSlice.reducer;
