import customFetch, { checkForUnauthorizedResponse } from '../../utils/axios';

/**
 * 
 * @param {any} _
 * @param {any} thunkAPI
 */
export const getAllDepartmentsThunk = async (allDepartments = false, thunkAPI) => {
    
    const { page, search, limit } = thunkAPI.getState().allDepartments;

    if (!limit)
        limit = 10;

    let url = `/Department?page=${page}&limit=${limit}&allDepartments=${allDepartments}`;

    if (search) {
        url = url + `&search=${search}`;
    }
    else {
        url = url + `&search=""`;
    }

    try {
        const resp = await customFetch.get(url);
        return resp.data;
    } catch (error) {
        return checkForUnauthorizedResponse(error, thunkAPI);
    }
};

/**
 * 
 * @param {any} slotRecId
 * @param {any} thunkAPI
 */
export const getNotIncludedSlotDepartmentsThunk = async (slotRecId, thunkAPI) => {
    const { page, search, limit } =
        thunkAPI.getState().allDepartments;

    if (!limit)
        limit = 10;

    let url = `/Slot/Departments/${slotRecId}?page=${page}&limit=${limit}&notIncluded=1`;
    if (search) {
        url = url + `&search=${search}`;
    }
    else {
        url = url + `&search=""`;
    }

    try {
        const resp = await customFetch.get(url);
        return resp.data;
    } catch (error) {
        return checkForUnauthorizedResponse(error, thunkAPI);
    }
};

/**
 * 
 * @param {any} employeeRecId
 * @param {any} thunkAPI
 */
export const getNotIncludedEmployeeDepartmentsThunk = async (employeeRecId, thunkAPI) => {
    const { page, search, limit } =
        thunkAPI.getState().allDepartments;

    if (!limit)
        limit = 10;

    let url = `/Employee/Departments/${employeeRecId}?page=${page}&limit=${limit}&notIncluded=1`;
    if (search) {
        url = url + `&search=${search}`;
    }
    else {
        url = url + `&search=""`;
    }

    try {
        const resp = await customFetch.get(url);
        return resp.data;
    } catch (error) {
        return checkForUnauthorizedResponse(error, thunkAPI);
    }
};

/**
 * 
 * @param {any} _
 * @param {any} thunkAPI
 */
export const getDepartmentsRelatedWithUserThunk = async (employeeRecId, thunkAPI) => {

    const search = "";
    const page = 1;
    const limit = 10;

    let url = `/Employee/Departments/${employeeRecId}?page=${page}&limit=${limit}`;
    if (search) {
        url = url + `&search=${search}`;
    }
    else {
        url = url + `&search=""`;
    }

    try {
        const resp = await customFetch.get(url);
        return resp.data;
    } catch (error) {
        return checkForUnauthorizedResponse(error, thunkAPI);
    }
};

export const showStatsThunk = async (_, thunkAPI) => {
  try {
    const resp = await customFetch.get('/departments/stats');

    return resp.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};
